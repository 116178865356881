import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import { refund, refund2 } from '@util/string'
import useAlert from '@hooks/useAlert'
import SemuPartView from '@pages/ask/semu/semu.part.view'
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined'
import RestPartView from '@pages/ask/rest/rest.part.view'
import MinwonView from '@components/MinwonView'
const MyOrderViewPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)
  const navigate = useNavigate()
  const location = useLocation()
  const order = location.state?.order ?? {}
  console.log('order=>', order)
  const docs = order?.docs ?? []
  const attchs = order?.attchs ?? []
  const curLocationRef = useRef(location)
  const { alert, renderAlert } = useAlert()
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const estCodes = codes.filter((r) => r.codeGroupId == 'D006')
  const bizCodes = codes.filter((r) => r.codeGroupId == 'D004')
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const [recvAmount, set_recvAmount] = useState(0)
  const [serviceAmount, set_serviceAmount] = useState(0)
  const claim = location.state?.claim

  const quotePrice = (doc) => {
    const code = docCodes.find(r => r.code === doc.docCode)
    if(code.code === 'DS07') {
      const code2 = bizCodes.find(r => r.code === doc.bizCode)
      return doc.giveYn === 'Y' ? code2.note2 : code2.note1
    }
    else if(code.code === 'DG01') {
      const code2 = estCodes.find(r => r.code === doc.estCode)
      return doc.giveYn === 'Y' ? code2.note2 : code2.note1
    }
    else {
      return doc.giveYn === 'Y' ? code.note2 : code.note1
    }
  }
  const onLoad = async () => {}

  const onClaim = async () => {
    const url = '/order/update'
    const data = {
      record: { ...order, claimYn: 'Y' },
    }
    const resp = await WebService.post(url, data)
    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    showSnackbar('취소신청되었습니다.')
    if (pack?.url) {
      navigate(pack.url, {
        replace: true,
        state: {
          pack: pack,
        },
      })
    } else {
      navigate(-1)
    }
  }

  useEffect(() => {
    const ramount = order.recvCodes.split(',').reduce((acc, cur) => {
      return acc + Number(recvCodes.find((r) => r.code === cur).note1)
    }, 0)
    const totQty = order.docs.reduce((acc, cur) => {
      return acc + cur.qty
    }, 0)

    const recvAmount = ramount * totQty

    set_recvAmount(recvAmount)

    const serviceAmount = order.docs.reduce((acc, cur) => {
      const price = quotePrice(cur)
      return acc + price * cur.qty
    }, 0)

    set_serviceAmount(serviceAmount)
  }, [])

  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  useEffect(() => {
    onLoad()
  }, [])

  return (
    <div style={{ paddingTop: isMobile ? 50 : 100 }}>
      {renderAlert()}
      <Container maxWidth='md'>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <IconButton
              style={{ backgroundColor: '#fafafa' }}
              onClick={() => {
                const pack = location.state?.pack
                console.log('pack=>', pack)
                if (pack?.url) {
                  navigate(pack.url, {
                    replace: true,
                    state: {
                      pack: pack,
                    },
                  })
                } else {
                  navigate(-1)
                }
              }}
            >
              <ArrowBackOutlined />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h5' style={{ fontWeight: 'bold' }}>
              수령방법
            </Typography>
          </Grid>
          {order.recvCodes.split(',').map((cd, idx) =>
            (() => {
              const recvCode = recvCodes.find((r) => r.code === cd)
              const findRecv = () => {
                if (recvCode.code === 'R002') {
                  return `E-MAIL: ${order.email}`
                }
                if (recvCode.code === 'R004') {
                  return `FAX: ${order.faxDial} - ${order.fax1} -${order.fax2}`
                }
                if (recvCode.code === 'R005') {
                  return `수신주소: ${order.recvAddr1} ${order.recvAddr2}`
                } else {
                  return ''
                }
              }
              return (
                <MinwonView
                  label={recvCode.name}
                  component={
                    <Typography>
                      {comma(recvCode.note1)} 원 {findRecv()}
                    </Typography>
                  }
                />
              )
            })()
          )}
          <MinwonView label='수령방법합계' component={<Typography>{comma(recvAmount)} 원</Typography>} />

          <Grid item xs={12}>
            <Divider />
          </Grid>
          {docs.map((doc, idx) => (
            <Grid item xs={12}>
              {doc.docCode?.startsWith('DS') ? <SemuPartView doc={doc} /> : <RestPartView doc={doc} />}
            </Grid>
          ))}
          <Grid item xs={12}>
            <MinwonView label='총금액' component={<Typography>{comma(serviceAmount + recvAmount)} 원</Typography>} />
          </Grid>
          {claim ? (
            <Grid item xs={12}>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <Typography style={{ whiteSpace: 'pre-wrap' }}>{refund}</Typography>
                  <Typography style={{ whiteSpace: 'pre-wrap', fontWeight:'bold' }}>{refund2}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Button variant='contained' onClick={onClaim}>
                    취소신청
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          ) : null}
        </Grid>
      </Container>
    </div>
  )
}

export default MyOrderViewPage
