import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import MyDialog2 from '@components/MyDialog2'
import StateChanger from '@components/StateChanger'
import MyPagination from '@components/MyPagination'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ko'
import MobileLabel from '@components/MobileLabel'
import { truncate } from '@util/helper'
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined'
const boldFont = {
  fontWeight: 'bold',
}
import NoticePage from '@pages/notice/notice.page'

const defPageInfo = { pageNum: 0, pageSize: 10 }

const AdminNoticePage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return (
    <div
      style={{
        paddingTop: isMobile ? 50 : 100,
        paddingBottom: 50,
        minHeight: '75vh',
      }}
    >
      <Container >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
          >
            <Typography style={boldFont}>공지사항</Typography>
          </Grid>
        </Grid>
      </Container>

      <NoticePage origin='admin'/>
    </div>
  )
}

export default AdminNoticePage
