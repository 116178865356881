import * as React from 'react'
import { useEffect, useLayoutEffect, useRef, useState } from 'react'
import Button from '@mui/material/Button'
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'
import { useSelector } from 'react-redux'
import {
  Divider,
  IconButton,
  Select,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText,
  useTheme,
  useMediaQuery,
  Container,
} from '@mui/material'
import { fullCopy, isValidDate, printVariable } from '@util/helper'
import WebService from '@util/webService'
import useSnackbar from '@hooks/useSnackbar'
import useIndicator from '@hooks/useIndicator'
import axios from 'axios'
import { Fragment } from 'react'
import moment from 'moment'
import { Grid } from '@mui/material/'
import useDelegate from '@hooks/useDelegate'
import { styled } from '@mui/material/styles'
import ConfirmDialog from '@components/ConfirmDialog'

import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import 'react-quill/dist/quill.bubble.css'
import CircularProgress from '@mui/material/CircularProgress'
import loading from '@images/loading.gif'
import ImageResize from 'quill-image-resize'
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined'
// date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ko'
import { TimePicker } from '@mui/x-date-pickers/TimePicker'
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined'
import CloseIcon from '@mui/icons-material/Close'
import { attempt } from 'lodash'
import useAlert from '@hooks/useAlert'
import CloudUploadIcon from '@mui/icons-material/CloudUpload'
import { useLocation, useNavigate } from 'react-router-dom'
Quill.register('modules/ImageResize', ImageResize)

// Component 안에 있으면 ReqactQuill 이 사라지는 현상 발생하여 밖으로 빼냄.
const modules = {
  toolbar: [
    [{ header: [1, 2, 3, 4, 5, 6, false] }],
    ['bold', 'italic', 'underline', 'strike', 'blockquote'],
    [{ size: [] }],
    [{ font: [] }],
    [{ align: ['right', 'center', 'justify'] }],
    [{ list: 'ordered' }, { list: 'bullet' }],
    ['link', 'image'],
    [{ color: [] }],
    [{ background: [] }],
  ],
  keyboard: {
    bindings: {
      'list autofill': {
        // * + space 자동 포맷 방지
        key: ' ',
        collapsed: true,
        format: { list: false, testListNum: false, testListBul: false },
        prefix: /^(1\.|-)$/,
        handler: function (range, context) {},
      },
    },
  },
  ImageResize: {
    parchment: Quill.import('parchment'),
  },
}

const NoticePartView2 = () => {
  console.log('?????????????????????????????????')
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()

  // redux
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'
  const editorRef = useRef(null)
  const [attchList, set_attchList] = useState([])
  const [rendered, set_rendered] = useState(false)
  const quillRef = useRef(null)
  const titleRef = useRef(null)
  const [oldContents, set_oldContents] = useState([])
  const [insImgUrls, set_insImgUrls] = useState([])
  const [files, set_files] = useState([])
  const { alert, renderAlert } = useAlert()
  const location = useLocation()
  const pack = location.state?.pack
  const record = pack?.data
  console.log('record=>', record)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const navigate = useNavigate()
  return (
    <div style={{ paddingTop: isMobile ? 50 : 100 }}>
      {renderAlert()}
      <Container >
        <Grid container spacing={2}>
          <Grid item xs={12} style={{display:'flex', alignItems:'center'}}>
            <IconButton
              style={{ backgroundColor: '#fafafa' }}
              onClick={() => {
                const pack = location.state?.pack
                console.log('pack=>', pack)
                if (pack?.url) {
                  navigate(pack.url, {
                    replace: true,
                    state: {
                      pack: pack,
                    },
                  })
                } else {
                  navigate(-1)
                }
              }}
            >
              <ArrowBackOutlined />
            </IconButton>
            <Typography variant='h5' style={{marginLeft:10}}>공지사항</Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography>{record.title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <div style={{ height: '100vh' }} dangerouslySetInnerHTML={{ __html: record.content}} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default NoticePartView2
