import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import MyDialog2 from '@components/MyDialog2'
import StateChanger from '@components/StateChanger'
import MyPagination from '@components/MyPagination'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ko'
import MobileLabel from '@components/MobileLabel'
import NumericTextField from '@components/NumericTextField'
import consoleLogger from 'react-terminal-logger/console-logger'
const boldFont = {
  fontWeight: 'bold',
}

const defPageInfo = { pageNum: 0, pageSize: 10 }

const AdminAgencyPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useSelector((state) => state.accountReducer)
  const codes = useSelector((state) => state.codeReducer)
  const [records, set_records] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const pack = location.state?.pack
  const curLocationRef = useRef(location)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const { alert, renderAlert } = useAlert()
  const [pageInfo, set_pageInfo] = useState(pack?.data ?? defPageInfo)
  const [termsKey, set_termsKey] = useState(0)
  const [terms, _set_terms] = useState({})
  const termsRef = useRef(terms)

  const fetchAgency = async () => {
    const url = '/agency'
    const resp = await WebService.get(url, {
      pageNum: pageInfo.pageNum,
      pageSize: pageInfo.pageSize,
    })

    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    records.forEach((item) => {
      item.civilNum1 = item.civilNum.take(6)
      item.civilNum2 = item.civilNum.takeRight(7)
    })
    return records
  }

  const validate = () => {
    const agency = records[0]
    if(!agency.name) {
      showSnackbar('이름을 입력해 주세요.', theme.palette.warning.dark)
      return
    }

    if(agency.civilNum1.length != 6) {
      showSnackbar('주민번호 앞자리를 정확히 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if(agency.civilNum2.length !== 7) {
      showSnackbar('주민번호 뒷자리를 정확히 입력해 주세요.', theme.palette.warning.dark)
      return
    }

    if(agency.phoneDial.length < 2) {
      showSnackbar('국번을 정확히 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if(agency.phone1.length < 3) {
      showSnackbar('전화번호 앞자리를 정확히 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    if(agency.phone2.length < 4) {
      showSnackbar('전화번호 앞자리를 정확히 입력해 주세요.', theme.palette.warning.dark)
      return
    }

    agency.phone = `${agency.phoneDial}${agency.phone1}${agency.phone2}`
    agency.civilNum = `${agency.civilNum1}${agency.civilNum2}`
    return agency
  }

  const onLoad = async () => {
    const records = await fetchAgency()
    set_records(records)
  }

  const onSave = async() => {
    if(records.length !== 1) {
      alert('데이터를 저장할 수 없습니다.')
      return
    }

    const ret = validate()
    if(!ret) {
      return
    }

    const url = '/agency'
    const data = {
      agency: ret
    }

    const resp = await WebService.post(url, data)
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    alert('저장되었습니다.')
  }

  useEffect(() => {
    onLoad()
  }, [pageInfo])
  return (
    <div
      style={{
        paddingTop: isMobile ? 50 : 100,
        paddingBottom: 50,
        minHeight: '75vh',
      }}
    >
      <Container maxWidth='md'>
        {renderAlert()}
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
          >
            <Typography style={boldFont}>위임받는사람</Typography>
          </Grid>
          {records &&
            records.map((item, idx) => (
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography style={boldFont}>이름</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      placeholder='이름'
                      defaultValue={item.name}
                      onChange={(e) => {
                        item.name = e.target.value
                      }}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={boldFont}>주민번호</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={6}>
                        <NumericTextField
                          fullWidth
                          placeholder='주민번호앞자리'
                          maxLength={6}
                          value={item.civilNum1}
                          onChange={(e) => {
                            item.civilNum1 = e
                            set_records([...records])
                          }}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <NumericTextField
                          fullWidth
                          placeholder='주민번호뒷자리'
                          maxLength={7}
                          value={item.civilNum2}
                          onChange={(e) => {
                            item.civilNum2 = e
                            set_records([...records])
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography style={boldFont}>전화번호</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <NumericTextField
                          fullWidth
                          placeholder='국번'
                          maxLength={4}
                          value={item.phoneDial}
                          onChange={(e) => {
                            item.phoneDial = e
                            set_records([...records])
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumericTextField
                          fullWidth
                          placeholder='전화번호앞자리'
                          maxLength={4}
                          value={item.phone1}
                          onChange={(e) => {
                            item.phone1 = e
                            set_records([...records])
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <NumericTextField
                          fullWidth
                          placeholder='전화번호뒷자리'
                          maxLength={4}
                          value={item.phone2}
                          onChange={(e) => {
                            item.phone2 = e
                            set_records([...records])
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            ))}
          <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
            <Button variant='contained' onClick={onSave}>저장</Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default AdminAgencyPage
