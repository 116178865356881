import { printVariable } from "@util/helper"
import {useEffect, useState, useRef, useCallback} from "react"
import consoleLogger from "react-terminal-logger/console-logger"
import { debounce, throttle } from 'lodash';


const useDelegate = ({initialValues, onSubmit, validate}) => {

  const [values, set_values] = useState(initialValues)
  const [errors, _set_errors] = useState({})
  const [canSubmit, set_canSubmit] = useState(false)

  const errorsRef = useRef(errors)
  const valuesRef = useRef(values)

  const set_errors = (errs) => {
    errorsRef.current = errs
    _set_errors(errs)
  }

  const handleChange = async(event, callback) => {
    if(event) {
      const name = event.target.name
      const value = event.target.value

      valuesRef.current[name] = value

      if(callback) {
        callback()
      }
      
      // console.log(`set name:${name} value:${value}`)
      // const newValues = {...valuesRef.current}
      // console.log('newValues=>', JSON.stringify(newValues, 0, 2))
      // set_values(newValues)

      const oldError = errorsRef.current[name]
      const newError = await validate(name, value)

      if(oldError != newError ) {
        const newErrors = {...errorsRef.current}
        newErrors[name] = newError
        set_errors(newErrors)
      }
    }
  }


  const updateState = (data)=> {
    if(data) {
      valuesRef.current = data
    }

    // const newValues = {...valuesRef.current}
    const newValues = JSON.parse(JSON.stringify(valuesRef.current))
    console.log('newValues=>', JSON.stringify(newValues, 0, 2))
    set_values(newValues)
  }

  const handleSubmit = async(event) => {
    // 모든 데이터의 상태 값 현행화, useEffect 에서 반영되므로, useEffect 에서 submit 처리
    set_canSubmit(false)
    updateState()
    //


    event?.preventDefault()
    const newErrors = {...errorsRef.current}
    
    console.log('newErrors=>', newErrors)
    // 필드 입력 값 체크
    for(const [name, value] of Object.entries(valuesRef.current)) {
      const err = await validate(name, value)
      newErrors[name] = err
    }

    let errCount = 0

    // 필드 입력 만으로는 알 수 없는 에러 체크 
    errCount = Object.values(newErrors).filter(r => r).length
    if(1 == 1) {
      const error = await validate('error', '')
      newErrors.error  = error
    }

    set_errors(newErrors)

    // 에러 없으면 canSubmit 
    errCount = 0
    for(const [name, value] of Object.entries(newErrors)) {
      if(value) {
        errCount = errCount + 1
      }
    }

    if(errCount === 0) {
      set_canSubmit(true)
    }
  }

  useEffect(() => {
    if(canSubmit && onSubmit) {
      onSubmit()
      set_canSubmit(false)
    }
  }, [canSubmit])


  // useEffect(() => {
  //   if (!shallowEqual(initialValues, values)) {
  //     set_values(initialValues);
  //   }
  // }, [initialValues]);

  return { activeRow: values, errors: errors, updateState, handleChange, handleSubmit, factor: valuesRef.current}
}

export default useDelegate
