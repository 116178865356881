import * as React from 'react';
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {useSelector} from 'react-redux';
import {
  Divider,
  IconButton,
  Select,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  FormHelperText
} from '@mui/material';
import {fullCopy, isValidDate, printVariable} from '@util/helper';
import WebService from '@util/webService';
import useSnackbar from '@hooks/useSnackbar';
import useIndicator from '@hooks/useIndicator';
import axios from 'axios';
import {Fragment} from 'react';
import moment from 'moment';
import {Grid} from "@mui/material/";
import useDelegate from "@hooks/useDelegate";
import {styled} from "@mui/material/styles";
import ConfirmDialog from "@components/ConfirmDialog";

import ReactQuill, {Quill} from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import 'react-quill/dist/quill.bubble.css';
import CircularProgress from "@mui/material/CircularProgress";
import loading from '@images/loading.gif'
import ImageResize from 'quill-image-resize';

// date
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import 'dayjs/locale/ko';
import {TimePicker} from "@mui/x-date-pickers/TimePicker";
import AttachFileOutlinedIcon from '@mui/icons-material/AttachFileOutlined';
import CloseIcon from '@mui/icons-material/Close';
import { attempt } from 'lodash';
import useAlert from "@hooks/useAlert";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
Quill.register('modules/ImageResize', ImageResize);

// Component 안에 있으면 ReqactQuill 이 사라지는 현상 발생하여 밖으로 빼냄.
const modules = {
  toolbar: [
    [{header: [1, 2, 3, 4, 5, 6, false]}],
    ["bold", "italic", "underline", "strike", "blockquote"],
    [{size: []}],
    [{font: []}],
    [{align: ["right", "center", "justify"]}],
    [{list: "ordered"}, {list: "bullet"}],
    ["link", "image"],
    [{color: []}],
    [{background: []}]
  ],
  keyboard: {
    bindings: {
      'list autofill': { // * + space 자동 포맷 방지
        key: ' ',
        collapsed: true,
        format: { list: false , 'testListNum': false, 'testListBul': false},
        prefix: /^(1\.|-)$/,
        handler: function(range, context) {}
      }
    }
  },
  ImageResize: {
    parchment: Quill.import('parchment')
  },
};

const NoticePartView = ({record, dialogHandler, callback}) => {
  const [openSnackbar, renderSnackbar] = useSnackbar()
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()

  // redux
  const codes = useSelector(state => state.codeReducer);
  const account = useSelector(state => state.accountReducer);
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const editorRef = useRef(null);
  const [attchList, set_attchList] = useState([])
  const [rendered, set_rendered] = useState(false)
  const quillRef = useRef(null)
  const titleRef = useRef(null)
  const [oldContents, set_oldContents] = useState([])
  const [insImgUrls, set_insImgUrls] = useState([])
  const [files, set_files] = useState([])
  const {alert, renderAlert} = useAlert()
  
  const handleClose = async (event, reason) => {
    if (reason && reason === "backdropClick") {
      return
    } else {
      await onCancel()
      dialogHandler[1](false)
      if (callback) {
        callback()
      }
    }
  }

  const onChangeFile = (e) => {
		const files = Array.from(e.target.files)
    set_files(files)
    set_attchList([]) 
  }

  const fetchAttchList = async () => {
    const url = `/notice/attchs/${record.id}`
    openIndicator()
    const resp = await WebService.get(url)
    closeIndicator()
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    set_attchList(records)
  }

  const onLoad = async () => {
    await fetchAttchList()
  }

  const validate = async (name, value) => {
    let err = ''
    if (name === 'catCode') {
      if (!value) {
        err = '구분이 선택되지 않았습니다.'
      }
    }
    if (name === 'title') {
      if (!value) {
        err = '제목이 비어 있습니다.'
      }
    }
    if (name === 'content') {
      const imageTagRegex = /<img.*?>/i

      // 이미지만 있어도 OK
      if(imageTagRegex.test(value)) {
      }
      else {
        if(value.replace(/<(.|\n)*?>/g, '').trim().length === 0) {
          err = '내용이 비어 있습니다.'
          
        }
      }
    }
    if(name === 'day') {
      if(activeRow.catCode === 'S002') {
        if(!moment(value, dateFormat, true).isValid()) {
          err = '날짜 형식에 맞지 않습니다.'
        }
      }
    }
    if(name === 'time1') {
      if(activeRow.catCode === 'S002') {
        if(!moment(value, dateFormat, true).isValid()) {
          err = '시작시간이 형식에 맞지 않습니다.'
        }
      }
    }
    if(name === 'time2') {
      if(activeRow.catCode === 'S002') {
        if(!moment(value, dateFormat, true).isValid()) {
          err = '종료시간이 형식에 맞지 않습니다.'
        }
      }
    }
    if(name === 'error') {
      if(activeRow.catCode === 'S002') {
        const time1 = moment(activeRow.time1, dateFormat)
        const time2 = moment(activeRow.time2, dateFormat)
        if(!time1.isBefore(time2)) {
          err = '시작시간은 종료시간보다 전이어야 합니다.'
        }
      }
    }

    return err
  }

  const onSubmit = async () => {

    const row = {...activeRow}
    if(activeRow.catCode === 'S002') {
      const sTime = moment(activeRow.day, dateFormat).format('YYYY-MM-DD') + ' ' + moment(activeRow.time1, dateFormat).format('HH:mm:00')
      const eTime = moment(activeRow.day, dateFormat).format('YYYY-MM-DD') + ' ' + moment(activeRow.time2, dateFormat).format('HH:mm:00')
      row.sTime = sTime
      row.eTime = eTime
    }
    else {
      row.sTime = ''
      row.eTime = ''
    }

    const url = `/notice`
    const oldImgUrls = getImgUrls(oldContents)
    const newImgUrls = getImgUrls(quillRef.current.getEditor().getContents())
    const data = {
      oldImgUrls: oldImgUrls,
      newImgUrls: newImgUrls,
      insImgUrls: insImgUrls,
      row: JSON.stringify(row),
      oldAttchIdList: attchList.map(a => a.id)
    }


    const formData = new FormData()
    files.forEach((file, index) => {
      if (file instanceof File) {
        formData.append(`files`, file)
      }
    })

    console.log('files => ', files)

    const jsonData = JSON.stringify(data)
    
    formData.append(
      "body",
      new Blob([jsonData], { type: "application/json" })
    )

    const resp = await axios.post(url, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    })

    if (resp.data.repCode === 'ack') {
       alert('저장되었습니다.')
      const repMessage = JSON.parse(resp.data.repMessage)
      const record = JSON.parse(repMessage.record)

      await handleChange({target: {name: 'id', value: record.id}})
      updateState()

      //
      set_oldContents(quillRef.current.getEditor().getContents())
      set_insImgUrls([])
      if (callback) {
        callback()
      }
    } else {
      alert(resp.repMessage)
    }
  }


  const onCancel = async() => {
    if(insImgUrls.length > 0) {
      // delete files in sever
      const url = `/bucket/del/img`
      const data = {
        insImgUrls: insImgUrls
      }

      const resp = await WebService.post(url, data)
      if (resp.repCode === 'ack') {

      } else {
        console.log(resp.repMessage)
      }
    }
  }

  const {activeRow, errors, updateState, handleChange, handleSubmit, factor} = useDelegate({
    initialValues: {...record},
    onSubmit: onSubmit,
    validate: validate,
  });

  const getImgUrls = (delta) => {
    return delta.ops.filter(i => i.insert && i.insert.image).map(i => i.insert.image)
  }

  const handleDelta = (delta) => {
    const inserted = getImgUrls(delta).filter(r => !r.startsWith("data:"))
    const newInsImgUrls = [...insImgUrls, ...inserted]
    set_insImgUrls(newInsImgUrls)
  }

  const catCodeList = codes.filter(r => r.codeGroupId === 'SA24')

  const FormHelperTexts = styled(FormHelperText)`
    width: 100%;
    padding-left: 16px;
    font-weight: 700 !important;
    color: #d32f2f !important;
  `;

  useEffect(() => {
    onLoad()
  },[])
  // useEffect(() => {
  //   if(dialogHandler[0]) {
  //     onLoad()
  //   }
  // },[dialogHandler[0]])


  const uploadImage = async(file) => {
    const url = `/bucket/add/img`
    const formData = new FormData()
    formData.append(`files`, file)
    const resp = await axios.post(url, formData, {
      headers: {
        'Content-Type': 'multipart/form-data; charset=UTF-8',
      },
    })
    if (resp.data.repCode === 'ack') {
      const repMessage = JSON.parse(resp.data.repMessage)
      return repMessage.imgUrl
    } else {
      return undefined
    }
  }

  const handleImage = async () => {
    if(quillRef.current) {

      const editor = quillRef.current.getEditor()
      const input = document.createElement("input");
      input.setAttribute("type", "file");
      input.setAttribute("accept", "image/*");
      input.click();
      input.onchange = async () => {
        const file = input.files[0];

        // 현재 커서 위치 저장
        const range = editor.getSelection(true);

        // 서버에 올려질때까지 표시할 로딩 placeholder 삽입
        editor.insertEmbed(range.index, "image", loading);
        const imgUrl = await uploadImage(file)
        editor.deleteText(range.index, 1);
        if(imgUrl) {
          editor.insertEmbed(range.index, "image", imgUrl);
          // 사용자 편의를 위해 커서 이미지 오른쪽으로 이동
          editor.setSelection(range.index + 1);
        }
      };
    }
    else {
      openSnackbar('에디터에 오류가 발생했습니다.')
    }
  }

  useEffect(() => {
    if(rendered) {
      if(quillRef.current) {
        const editor = quillRef.current.getEditor()
        const toolbar = editor.getModule('toolbar')
        toolbar.addHandler('image', handleImage)
        const contents = editor.getContents()
        set_oldContents(contents)
      }
      else {
      }

      set_rendered(false)
    }

  }, [rendered]);


  useEffect(() => {
    set_rendered(true)
  }, []);

  return (
    <Fragment>
      <Dialog open={dialogHandler[0]} onClose={handleClose} maxWidth='md' fullWidth>
        <DialogTitle>
          <Typography variant='h5' align='center'>
            공지사항
          </Typography>
          <Divider></Divider>
        </DialogTitle>
        <DialogContent>
          <br/>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography style={{fontWeight:'bold'}}>{record.title}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <div style={{minHeight:'50vh'}} dangerouslySetInnerHTML={{ __html: record.content}} />
            </Grid>
           
            {/* <Grid item xs={12}> */}
            {/*   <Button component="label" variant="outlined" startIcon={<AttachFileOutlinedIcon />}> */}
            {/*     첨부 */}
            {/*     <input */}
            {/*       styles={{display:"none"}} */}
            {/*       type="file" */}
            {/*       accept='.txt, .jpg, .png, .pdf, .doc, .docx, .ppt, .pptx, .xls, .xlsx, .hwp'  */}
            {/*       hidden */}
            {/*       multiple */}
            {/*       onChange={(e)=> {onChangeFile(e)}} */}
            {/*     /> */}
            {/*   </Button> */}
            {/* </Grid> */}
            {/* <Grid item xs={12} sx={{display:'flex', flexDirection:'column'}}> */}
            {/*   { */}
            {/**/}
            {/*     files.length > 0 ? ( */}
            {/*       files.map((item, idx) =>( */}
            {/*         <div key={idx} style={{display:'flex', flexDirection:'row'}}> */}
            {/*           <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center' }}> */}
            {/*             <Typography variant='body2' style={{marginRight:5}} > */}
            {/*               {item.name}  */}
            {/*             </Typography> */}
            {/*             <CloseIcon style={{fontSize:'1em', cursor:'pointer'}} onClick={() => { */}
            {/*               const new_files = [...files] */}
            {/*               new_files.splice(idx, 1)  */}
            {/*               set_files(new_files) */}
            {/*             }}/> */}
            {/*           </div> */}
            {/*         </div> */}
            {/*       )) */}
            {/*     ) : ( */}
            {/*       attchList.map((item, idx) =>( */}
            {/*         <div key={idx} style={{display:'flex', flexDirection:'row'}}> */}
            {/*           <div style={{display:'flex', flexDirection:'row', justifyContent:'center', alignItems:'center' }}> */}
            {/*             <Typography variant='body2' style={{marginRight:5}} > */}
            {/*               {item.name}  */}
            {/*             </Typography> */}
            {/*             <CloseIcon style={{fontSize:'1em', cursor:'pointer'}} onClick={() => { */}
            {/*               const new_attachList = [...attchList]  */}
            {/*               new_attachList.splice(idx, 1)  */}
            {/*               set_attchList(new_attachList) */}
            {/*             }}/> */}
            {/*           </div> */}
            {/*         </div> */}
            {/*       )) */}
            {/*     ) */}
            {/*   } */}
            {/* </Grid> */}
          </Grid>
        </DialogContent>
        <DialogActions style={{display:'flex', justifyContent:'center'}}>
          <Button onClick={handleClose}>
            닫기
          </Button>
        </DialogActions>
      </Dialog>
      {renderSnackbar()}
      {renderIndicator()}
      {renderAlert()}
    </Fragment>
  )
}

export default NoticePartView
