import React, { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import { useSelector, useDispatch } from 'react-redux'
import useAlert from '@hooks/useAlert'
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  Grid,
  Box,
  Typography,
  Container,
  useTheme,
  IconButton,
} from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import styled from 'styled-components'
import WebService from '../util/webService'
import useSnackbar from '../hooks/useSnackbar'
import { setAccount } from '../redux/actions/accountAction'
import { isAdmin, isStringNullOrEmpty } from '../util/helper'
import useIndicator from '@hooks/useIndicator'
import { isExpired, decodeToken } from 'react-jwt'
import logo from '@images/logo.png'
import { showSnackbar } from '@context/SnackbarContext'
import { emailRegex, nameRegex, passwordRegex } from '@util/helper'
import NumericTextField from '@components/NumericTextField'
import { useLocation } from 'react-router-dom'
import { ArrowBackOutlined } from '@mui/icons-material'
import { siteTitle } from '@util/string'
// mui의 css 우선순위가 높기때문에 important를 설정 - 실무하다 보면 종종 발생 우선순위 문제
const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

//
const Boxs = styled(Box)`
  padding-bottom: 40px !important;
`

const LoginPage = () => {
  const theme = useTheme()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { alert, renderAlert } = useAlert()
  const buttonRef = useRef(null)
  const location = useLocation()
  const doc = location.state?.doc ?? {}
  const pack = location?.state?.pack
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      buttonRef.current.click()
    }
  }

  const onSuccess = (resp) => {
    const repMessage = JSON.parse(resp.repMessage)
    console.log('long suc =>', repMessage)
    const account = JSON.parse(repMessage.account)
    dispatch(setAccount(account))

    const token = repMessage.token
    const decoded = decodeToken(token)

    const accessToken = repMessage.accessToken
    localStorage.setItem('accessToken', accessToken)

    const refreshToken = repMessage.refreshToken
    localStorage.setItem('refreshToken', refreshToken)

    const deviceId = repMessage.deviceId
    localStorage.setItem('deviceId', deviceId)

    if (pack?.furl) {
      navigate(pack.furl, {
        replace: true,
        state: {
          docs: [doc],
        },
      })
    } else {
      navigate('/')
    }
  }

  const MemberLogin = () => {
    const [loginId, set_loginId] = useState('')
    const [password, set_password] = useState('')

    const canLogin = () => {
      return loginId && password
    }
    const onLogin = async () => {
      const url = '/login'
      if (!loginId || !password) {
        showSnackbar('아이디 또는 패스워드를 입력하세요.')
        return
      }
      const data = { loginId: loginId, password: password }

      const resp = await WebService.post(url, data)

      if (resp.repCode !== 'ack') {
        alert(resp.repMessage)
        return
      }

      onSuccess(resp)
    }

    useEffect(() => {
      document.addEventListener('keydown', handleKeyPress)
      return () => {
        document.removeEventListener('keydown', handleKeyPress)
      }
    }, [])
    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' style={{ fontWeight: 'bold', textAlign: 'center' }}>
              회원 로그인
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              autoFocus
              fullWidth
              type='text'
              id='loginId'
              name='loginId'
              label='이메일'
              size='md'
              onChange={(e) => {
                set_loginId(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type='password'
              id='password'
              name='password'
              label='패스워드'
              size='md'
              onChange={(e) => {
                set_password(e.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              ref={buttonRef}
              variant='contained'
              size='large'
              disabled={!canLogin()}
              onClick={() => onLogin()}
            >
              로그인
            </Button>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              {/* <Grid item xs={6} style={{ display: 'flex', justifyContent: 'center' }}> */}
              {/*   <Button variant='text' onClick={() => navigate('/public/find/id')}> */}
              {/*     아이디 찾기 */}
              {/*   </Button> */}
              {/* </Grid> */}
              <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
                <Button variant='text' onClick={() => navigate('/public/find/password')}>
                  패스워드 재설정
                </Button>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              size='large'
              onClick={() => {
                navigate('/register/user')
              }}
            >
              회원 가입
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  const TourLogin = () => {
    const theme = useTheme()
    const [user, set_user] = useState({
      name: doc.name ?? '',
      password: '',
      password2: '',
      phone: doc.phone ?? '',
      phoneDial: doc.phoneDial ?? '010',
      phone1: doc.phone1 ?? '',
      phone2: doc.phone2 ?? '',
    })

    const validate = () => {
      if (!user.name) {
        showSnackbar('이름 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      } else {
        if (!nameRegex.test(user.name)) {
          showSnackbar('정상적인 이름형식이 아닙니다.', theme.palette.warning.dark)
          return
        }
      }

      if (!user.password) {
        showSnackbar('패스워드 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      } else {
        if (!passwordRegex.test(user.password)) {
          showSnackbar('연속 3자리가 중복되지 않게 6자리 이상 를(을) 입력해 주세요.', theme.palette.warning.dark)
          return
        }
      }
      if (!user.password2) {
        showSnackbar('패스워드확인 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (user.password !== user.password2) {
        showSnackbar('패스워드가 일치하지 않습니다.', theme.palette.warning.dark)
        return
      }
      if (!user.phoneDial) {
        showSnackbar('국번 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }

      if (user.phone1.length != 4) {
        showSnackbar('전화번호앞자리 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if (user.phone2.length != 4) {
        showSnackbar('전화번호뒷자리 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }

      user.phone = `${user.phoneDial}${user.phone1}${user.phone2}`
      return true
    }

    const onLoginTour = async () => {
      const ret = validate()
      if (!ret) {
        return
      }
      const url = '/login/tour'

      const data = {
        name: user.name,
        password: user.password,
        phone: user.phone,
        phoneDial: user.phoneDial,
        phone1: user.phone1,
        phone2: user.phone2,
      }

      const resp = await WebService.post(url, data)

      if (resp.repCode !== 'ack') {
        alert(resp.repMessage)
        return
      }
      onSuccess(resp)
    }

    return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h6' style={{ fontWeight: 'bold', textAlign: 'center' }}>
              비회원 로그인
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type='text'
              label='이름'
              size='md'
              inputProps={{ maxLength: 20 }}
              value={user.name}
              onChange={(e) => {
                set_user({ ...user, name: e.target.value })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type='password'
              label='패스워드, 연속 3자리가 중복되지 않게 6자리 이상'
              size='md'
              inputProps={{ maxLength: 20 }}
              value={user.password}
              onChange={(e) => {
                set_user({ ...user, password: e.target.value })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              required
              fullWidth
              type='password'
              label='패스워드확인'
              size='md'
              inputProps={{ maxLength: 20 }}
              value={user.password2}
              onChange={(e) => {
                set_user({ ...user, password2: e.target.value })
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <NumericTextField required disabled value={user.phoneDial} />
              </Grid>
              <Grid item xs={4}>
                <NumericTextField
                  required
                  label='전화앞자리'
                  maxLength={4}
                  value={user.phone1}
                  onChange={(e) => {
                    set_user({ ...user, phone1: e })
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <NumericTextField
                  required
                  label='전화뒷자리'
                  maxLength={4}
                  value={user.phone2}
                  onChange={(e) => {
                    set_user({ ...user, phone2: e })
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Button
              fullWidth
              variant='contained'
              color='secondary'
              size='large'
              disabled={!user.name || !user.password || !user.password2 || !user.phone1 || !user.phone2}
              onClick={() => onLoginTour()}
            >
              비회원 로그인
            </Button>
          </Grid>
        </Grid>
      </>
    )
  }

  return (
    <>
      <Container component='main' maxWidth='md'>
        <CssBaseline />
        <Grid container spacing={5} style={{ marginTop: 50 }}>
          <Grid item xs={12}>
            <IconButton
              onClick={() => {
                const pack = location.state?.pack ?? {}
                if (pack?.burl) {
                  navigate(pack.burl, {
                    replace: true,
                    state: {
                      pack: pack,
                    },
                  })
                }
                else {
                  navigate('/')
                }
              }}
            >
              <ArrowBackOutlined />
            </IconButton>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h4' style={{ fontWeight: 'bold', textAlign: 'center' }}>
              {siteTitle} 로그인
            </Typography>
          </Grid>
          <Grid item xs={12} md={6}>
            <MemberLogin />
          </Grid>
          <Grid item xs={12} md={6}>
            <TourLogin />
          </Grid>
        </Grid>
      </Container>
      {renderAlert()}
    </>
  )
}

export default LoginPage
