import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
  TablePagination,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import MyDialog2 from '@components/MyDialog2'
import { check } from 'prettier'
import { setLoading } from '@context/LoadingContext'
import MyPagination from '@components/MyPagination'
import ConfirmDialog from '@components/ConfirmDialog'
import { EditOutlined } from '@mui/icons-material'
import { emailRegex, nameRegex, passwordRegex } from '@util/helper'
const boldFont = {
  fontWeight: 'bold',
}

const defPageInfo = { pageNum: 0, pageSize: 10 }
const MyInfoPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useSelector((state) => state.accountReducer)
  const codes = useSelector((state) => state.codeReducer)
  const [records, set_records] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const pack = location.state?.pack
  const curLocationRef = useRef(location)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const { alert, renderAlert } = useAlert()
  const dgRef = useRef()
  const [allChecked, set_allChecked] = useState(false)
  const [html, set_html] = useState('')
  const [totalCount, set_totalCount] = useState(0)
  const [pageInfo, set_pageInfo] = useState(pack?.data ?? defPageInfo)

  const handleAllChecked = (e) => {
    const checked = e.target.checked
    console.log('checked=>', checked)
    records.forEach((item) => {
      item.checked = checked
    })
    set_records([...records])
    set_allChecked(checked)
  }

  const handleEachChecked = (item, e) => {
    const checked = e.target.checked
    item.checked = checked
    const filtered = records.filter((r) => r.checked === checked)

    if (checked) {
      console.log('filtered length:', filtered.length)
      if (filtered.length === records.length) {
        console.log('ok all checked')
        set_allChecked(true)
      }
    } else {
      if (filtered.length !== records.length) {
        set_allChecked(false)
      }
    }

    set_records([...records])
  }



  const onLoad = async () => {
  }

  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  useEffect(() => {
    if (!account?.user?.id) {
      navigate('/login')
    } else {
      onLoad()
    }
  }, [pageInfo])

  const MyPassword = () => {
    const navigate = useNavigate()
    const theme = useTheme()
    const [password, set_password] = useState('')
    const [newPassword, set_newPassword] = useState('')
    const [newPassword2, set_newPassword2] = useState('')

    const validate = () => {
      if(!password)  {
        showSnackbar('패스워드 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }
      if(!passwordRegex.test(newPassword)) {
        showSnackbar('형식에 맞는 신규패스워드 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      }     
      if(!passwordRegex.test(newPassword)) {
        showSnackbar('형식에 맞는 신규패스워드2 를(을) 입력해 주세요.', theme.palette.warning.dark)
        return
      } 
      if(newPassword != newPassword2) {
        showSnackbar('신규패스워드가 일치하지 않습니다.', theme.palette.warning.dark)
        return
      }
      return true
    }
    
    const onPassword = async() => {
      const ret = validate()
      if(!ret) {
        return
      }

      const url = `/user/password/new/${account.user.id}`
      const data = {
        password: password,
        newPassword: newPassword,
      }

      const resp = await WebService.post(url, data)
      if(resp.repCode !== 'ack') {
        alert(resp.repMessage )
        return
      }

      alert('패스워드를 변경하였습니다. 로그아웃 합니다.', () => {
        navigate('/logout')
      })
    }

    return(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TextField fullWidth 
            label='기존 패스워드'
            type='password'
            value={password}
            onChange={e => {
              set_password(e.target.value)
            }} 
          />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth 
            label='신규패스워드, 연속 3자리가 중복되지 않게 6자리 이상'
            type='password'
            value={newPassword}
            onChange={e => {
              set_newPassword(e.target.value)
            }} 
          />
        </Grid>
        <Grid item xs={12}>
          <TextField fullWidth 
            label='신규패스워드 확인'
            type='password'
            value={newPassword2}
            onChange={e => {
              set_newPassword2(e.target.value)
            }} 
          />
        </Grid>
        <Grid item xs={12}>
          <Button fullWidth variant='contained'
            onClick={onPassword}
          >변경
          </Button>
        </Grid>
      </Grid>
    )
  }

  return (
    <div style={{ paddingTop: isMobile ? 50 : 100 }}>
      {renderAlert()}
      <MyDialog2 title={'패스워드 변경'} ref={dgRef} component={<MyPassword />} windowWidth='sm' />
      <Container style={{}}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant='h5'
              style={{ fontWeight: 'bold', }}
            >
             내 정보 
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={6}>
            <Typography variant='h6' style={boldFont}> 성명</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'> {account.user.name}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant='h6' style={boldFont}>ID</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'> {account.user.loginId}</Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography variant='h6' style={boldFont}>전화번호</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6'> {account.user.phone}</Typography>
          </Grid>
          <Grid item xs={6}>
            <Typography variant='h6' style={boldFont}>패스워드</Typography>
          </Grid>
          <Grid item xs={6}>
            <IconButton onClick={() => {
              dgRef.current.open()
            }}>
              <EditOutlined />
            </IconButton>
          </Grid>

        </Grid>
      </Container>
    </div>
  )
}

export default MyInfoPage
