import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import MyDialog2 from '@components/MyDialog2'
import StateChanger from '@components/StateChanger'
import MyPagination from '@components/MyPagination'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ko'
import MobileLabel from '@components/MobileLabel'
import ConfirmDialog from '@components/ConfirmDialog'
import consoleLogger from 'react-terminal-logger/console-logger'
import { EditOutlined } from '@mui/icons-material'
import { truncate } from '@util/helper'
import { TextareaAutosize } from '@mui/base/TextareaAutosize'
import { useAsyncDebounce } from 'react-table'

const boldFont = {
  fontWeight: 'bold',
}

const selectOptions = [
  { name: 'All', value: '' },
  { name: 'Y', value: 'Y' },
  { name: 'N', value: 'N' },
]
const defPageInfo = { pageNum: 0, pageSize: 10 }
const AdminIndexPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useSelector((state) => state.accountReducer)
  const codes = useSelector((state) => state.codeReducer)
  const [records, set_records] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const pack = location.state?.pack
  const curLocationRef = useRef(location)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const { alert, renderAlert } = useAlert()
  const [totalCount, set_totalCount] = useState(0)
  const [pageInfo, set_pageInfo] = useState(pack?.data ?? defPageInfo)
  const [termsKey, set_termsKey] = useState(0)
  const [terms, _set_terms] = useState({})
  const termsRef = useRef(terms)
  const orderRef = useRef({})
  const buttonRef = useRef(null)
  const set_terms = (data) => {
    termsRef.current = data
    _set_terms(data)
  }

  const dgRef = useRef()
  const remarkRef = useRef()

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      buttonRef.current.click();
    }
  };

  const fetchOrders = async () => {
    console.log('termsRef=>', termsRef.current)
    const url = `/order/admin`
    const resp = await WebService.get(url, {
      pageNum: pageInfo.pageNum,
      pageSize: pageInfo.pageSize,
      terms: JSON.stringify(termsRef.current),
    })

    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    const records = JSON.parse(repMessage.records)
    const totalCount = +repMessage.totalCount
    set_totalCount(totalCount)
    return records
  }

  const Minwon = () => {
    return (
      <div>
        <div style={{ height: '100vh' }} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    )
  }

  const onLoad = async () => {
    const records = await fetchOrders()
    if (!records) {
      return
    }
    console.log('records=>', records)
    set_records(records)
  }

  const onSearch = async () => {
    console.log('termsRef=>', termsRef)

    set_pageInfo({
      pageNum: 0,
      pageSize: 10,
    })
  }

  const onRejectClaim = async (order) => {
    console.log('order=============>', order)
    const url = '/order/update'
    const data = {
      record: { ...order, claimYn: 'N' },
    }

    console.log('data====>', data)
    const resp = await WebService.post(url, data)
    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    showSnackbar('취소거절 처리 되었습니다.')
    set_pageInfo({ ...pageInfo })
  }

  const onDelete = async(orderId) => {
    const url = `/order/${orderId}`
    const resp = await WebService.delete(url)
    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    showSnackbar('삭제되었습니다.')
    set_pageInfo({...pageInfo})
  }

  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      set_terms({})
      set_termsKey(termsKey + 1)
      onLoad()
    }
  }, [location])

  useEffect(() => {
    if (!account?.user?.id) {
      navigate('/login')
    } else {
      onLoad()
    }
  }, [pageInfo])

  useEffect(() => {
    document.addEventListener('keydown', handleKeyPress);
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
}, []);

  const Remark = () => {
    const [order, set_order] = useState(orderRef.current)

    const onSubmit = async () => {
      const url = '/order/update'
      const data = {
        record: order
      }
      const resp = await WebService.post(url, data)
      if(resp.repCode !== 'ack') {
        alert(resp.repMessage)
        return
      }
      else {
        showSnackbar('저장되었습니다.')
        dgRef.current.close()
        set_pageInfo({...pageInfo})
      }
    }
    return (
      <Grid container spacing={2}>
        <Grid item xs={12} style={{ fontWeight: 'bold' }}>
          <Typography>주문번호: {orderRef.current.orderNo}</Typography>
        </Grid>
        <Grid item xs={12}>
          <Divider />
        </Grid>
        <Grid item xs>
          <TextField
            rows={5}
            fullWidth
            multiline
            label={'비고'}
            defaultValue={order.remark}
            onChange={ e => {
              order.remark = e.target.value
            }}
          />
        </Grid>
        <Grid item xs={12} style={{display:'flex', justifyContent:'center'}}>
          <Button variant='contained'
            onClick={onSubmit}
          >저장</Button>
        </Grid>
      </Grid>
    )
  }
  return (
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
          paddingBottom: 50,
          minHeight: '75vh',
        }}
      >
        {renderAlert()}
        <MyDialog2 title={'비고'} ref={dgRef} component={<Remark />} windowWidth='sm' />
        <Container>
          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
            >
              <Typography style={boldFont}>민원처리</Typography>
            </Grid>
            <Grid item xs={12}>
              <Grid container spacing={1} key={termsKey}>
                <Grid item xs={6} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
                    <DatePicker
                      label={'From'}
                      value={terms.from ?? null}
                      inputFormat='YYYY-MM-DD'
                      onChange={(newValue) => {
                        const dtime = moment(new Date(newValue))
                        const d = dtime.format('YYYY-MM-DD')
                        set_terms({ ...terms, from: d })
                      }}
                      renderInput={(params) => <TextField fullWidth size='small' {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} md={3}>
                  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'ko'}>
                    <DatePicker
                      label={'To'}
                      value={terms.to ?? null}
                      inputFormat='YYYY-MM-DD'
                      onChange={(newValue) => {
                        const dtime = moment(new Date(newValue))
                        const d = dtime.format('YYYY-MM-DD')
                        set_terms({ ...terms, to: d })
                      }}
                      renderInput={(params) => <TextField fullWidth size='small' {...params} />}
                    />
                  </LocalizationProvider>
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField
                    fullWidth
                    placeholder='신청인성명'
                    defaultValue={terms.name}
                    onChange={(e) => {
                      terms.name = e.target.value
                      termsRef.current = terms
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField
                    fullWidth
                    placeholder='신청인전화번호'
                    defaultValue={terms.phone}
                    onChange={(e) => {
                      terms.phone = e.target.value
                      termsRef.current = terms
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <TextField
                    fullWidth
                    placeholder='주문번호'
                    defaultValue={terms.orderNo}
                    onChange={(e) => {
                      terms.orderNo = e.target.value
                      termsRef.current = terms
                    }}
                  />
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id='bid'>진행상태</InputLabel>
                    <Select
                      labelId='bid'
                      label='진행상태'
                      value={terms.statCode}
                      onChange={async (e) => {
                        set_terms({ ...terms, statCode: e.target.value })
                      }}
                    >
                      {statCodes.map((l, index) => {
                        return (
                          <MenuItem key={index} value={l.code}>
                            {l.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id='claim'>취소신청여부</InputLabel>
                    <Select
                      labelId='claim'
                      label='취소신청여부'
                      value={terms.claimYn}
                      onChange={(e) => {
                        set_terms({ ...terms, claimYn: e.target.value })
                      }}
                    >
                      {selectOptions.map((s, idx) => (
                        <MenuItem key={idx} value={s.value}>
                          {s.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6} md={3}>
                  <FormControl fullWidth>
                    <InputLabel id='docCode'>신청종류</InputLabel>
                    <Select
                      labelId='docCode'
                      label='신청종류'
                      value={terms.statCode}
                      onChange={async (e) => {
                        set_terms({ ...terms, docCode: e.target.value })
                      }}
                    >
                      {docCodes.map((l, index) => {
                        return (
                          <MenuItem key={index} value={l.code}>
                            {l.name}
                          </MenuItem>
                        )
                      })}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button
                    fullWidth
                    variant='contained'
                    onClick={() => {
                      set_terms({})
                      set_termsKey(termsKey + 1)
                    }}
                  >
                    검색초기화
                  </Button>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Button fullWidth variant='contained' onClick={onSearch} ref={buttonRef}>
                    찾기
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Divider style={{ marginTop: 5, marginBottom: 5 }} />
            </Grid>
            {isMobile ? null : (
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid container spacing={0} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs md>
                    <Typography style={boldFont}>주문번호</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>민원종류</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>수량</Typography>
                    <Typography style={boldFont}>금액</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>신청인/입금자</Typography>
                    <Typography style={boldFont}>수신방법</Typography>
                  </Grid>
                  {/* <Grid item xs md> */}
                  {/*   <Typography style={boldFont}>수신방법</Typography> */}
                  {/* </Grid> */}
                  <Grid item xs md>
                    <Typography style={boldFont}>신청일시</Typography>
                    <Typography style={boldFont}>최종수정일시</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>취소요청</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>비고</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>상태</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>삭제</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {records.length > 0
              ? records.map((item, idx) => (
                  <Grid item xs={12} key={item.id} onMouseOver={() => (orderRef.current = item)}>
                    <Grid
                      container
                      spacing={1}
                      key={idx}
                      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                    >
                      <Grid item xs={12} md>
                        <MobileLabel label='주문번호' />
                        <Typography variant='body2'>{item.orderNo}</Typography>
                      </Grid>
                      <Grid item xs={4} md>
                        <Grid
                          container
                          spacing={1}
                          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                        >
                          <Grid item xs={12}>
                            {item.docs.map((doc, idx2) => (
                              <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
                                <Grid item xs={12}>
                                  <Typography
                                    variant='body2'
                                    style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                    onClick={() => {
                                      navigate('/admin/order/view', {
                                        state: {
                                          order: item,
                                          pack: {
                                            url: window.location.pathname,
                                            data: pageInfo,
                                          },
                                        },
                                      })
                                    }}
                                  >
                                    {docCodes.find((r) => r.code === doc.docCode).name}
                                  </Typography>
                                </Grid>
                              </Grid>
                            ))}
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs md={1}>
                        <Typography variant='body2' style={{ display: 'flex' }}>
                          {item.docs.length} <MobileLabel label='매' />
                        </Typography>
                        <Typography variant='body2' style={{ display: 'flex' }}>
                          {comma(item.amount)} <MobileLabel label='원' />
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md>
                        <Typography variant='body2'>
                          {item.name}/{item.depositor}
                          <br />
                          {item.phone}
                          <br />
                          {item.recvCodes.split(',').map((it) => recvCodes.find((r) => r.code === it).name)}
                        </Typography>
                      </Grid>
                      {/* <Grid item xs={12} md> */}
                      {/*   <Typography variant='body2'> */}
                      {/*     {item.recvCodes.split(',').map((it) => recvCodes.find((r) => r.code === it).name)} */}
                      {/*   </Typography> */}
                      {/* </Grid> */}
                      <Grid item xs={12} md>
                        <MobileLabel label='신청일시' />
                        <Typography variant='body2' style={{ marginBottom: 5 }}>
                          {moment(item.regTime).format('YYYY-MM-DD')} <br />
                          {moment(item.regTime).format('HH:mm:ss')}
                        </Typography>
                        <MobileLabel label='최종수정일시' />
                        <Typography variant='body2'>
                          {moment(item.uptTime).format('YYYY-MM-DD')} <br />
                          {moment(item.uptTime).format('HH:mm:ss')}
                        </Typography>
                      </Grid>
                      <Grid item xs={12} md>
                        {item.claimYn === 'Y' ? (
                          <>
                            <Typography
                              variant='body2'
                              color={theme.palette.warning.dark}
                              style={{ fontWeight: 'bold' }}
                            >
                              취소요청됨
                            </Typography>
                            <ConfirmDialog
                              btn={<FlatButton color={'error'}>취소거절</FlatButton>}
                              title={'주의'}
                              content={'취소거절 처리 하시겠습니까?'}
                              bc={'error'}
                              preHandle={async () => {
                                return true
                              }}
                              perform={() => {
                                onRejectClaim(item)
                              }}
                            />
                          </>
                        ) : (
                          <Typography variant='body2'>취소없음</Typography>
                        )}
                      </Grid>
                      <Grid item xs={12} md style={{ display: 'flex', alignItems:'center' }}>
                        <Typography variant='body2'>{truncate(item.remark, 10)}</Typography>
                        <IconButton
                          onClick={(e) => {
                            dgRef.current.open()
                          }}
                        >
                          <EditOutlined />
                        </IconButton>
                      </Grid>
                      <Grid item xs={12} md={2}>
                        <StateChanger record={item} tbl={'orders'} callback={(statCode) => {
                          const new_data = [...records]
                          const found = new_data.find(r => r.id === item.id)
                          found.statCode = statCode
                          found.docs.forEach(d => {
                            console.log('change statCode of doc=>', d.id, statCode)
                            d.statCode = statCode
                          })

                          set_records(new_data)
                          
                        }}/>
                      </Grid>
                      <Grid item xs={12} md>
                        <ConfirmDialog
                          btn={<FlatButton color={'error'}>삭제</FlatButton>}
                          title={'주의'}
                          content={'삭제 하시겠습니까?'}
                          bc={'error'}
                          preHandle={async () => {
                            return true
                          }}
                          perform={() => {
                            onDelete(item.id)
                          }}
                        />

                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </Grid>
                ))
              : null}
            <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
              <MyPagination totalCount={totalCount} pageInfo={pageInfo} set_pageInfo={set_pageInfo} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default AdminIndexPage
