import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  Menu,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import { needPrfTerm } from '@pages/ask/semu/semu.util'

import useAlert from '@hooks/useAlert'
import MinwonView from '@components/MinwonView'
import AskDownloader from '@components/AskDownloader'
import PopupState, { bindMenu, bindTrigger } from 'material-ui-popup-state'
import { Blender } from '@mui/icons-material'
import MoreVertOutlined from '@mui/icons-material/MoreVertOutlined'
import ConfirmDialog from './ConfirmDialog'
import { isAdmin } from '@util/helper'

const StateChanger = ({ record, tbl, callback}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const docCodes = codes.filter((r) => r.codeGroupId == 'D001')
  const sepCodes = codes.filter((r) => r.codeGroupId == 'D009')
  const aimCodes = codes.filter((r) => r.codeGroupId == 'D003')
  const mockCodes = codes.filter((r) => r.codeGroupId == 'D010')
  const bizCodes = codes.filter((r) => r.codeGroupId == 'D004')
  const factCodes = codes.filter((r) => r.codeGroupId == 'D005')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const account = useSelector((state) => state.accountReducer)
  const navigate = useNavigate()
  const location = useLocation()
  const curLocationRef = useRef(location)
  const { alert, renderAlert } = useAlert()
  const [row, set_row] = useState(JSON.parse(JSON.stringify(record)))
  // const [row, set_row] = useState({...record})
  console.log('record=====>', record)
  console.log('row=====>', row)
  const onUpdate = async (statCode) => {
    let url = ``
    if (tbl === 'orders') {
      url = `/order/update`
    } else if (tbl == 'docs') {
      url = `/doc/update`
    }

    console.log('url=>', url)
    if (url) {
      const new_data = { ...row, statCode: statCode, uptTime: moment().format('YYYY-MM-DD HH:mm:ss') }
      const resp = await WebService.post(url, {
        record: new_data,
      })

      console.log(resp)
      if (resp.repCode !== 'ack') {
        alert(resp.repMessage)
        return
      }

      console.log('new_data=>', new_data)
      set_row(new_data)

      if(callback) {
        callback(statCode)
      }
    }
  }
  useEffect(() => {}, [])

  return (
    <>
      {renderAlert()}
      {isAdmin(account) ? (
        <PopupState variant='popover' popupId='popup-state'>
          {(popupState) => (
            <>
              <Button startIcon=<MoreVertOutlined /> style={{ marginRight: 10 }} {...bindTrigger(popupState)}>
                <Typography variant='body2' style={{fontSize:'0.9em'}}>
                  {statCodes.find((r) => r.code === row.statCode).name} 
                </Typography>
              </Button>
              <Menu {...bindMenu(popupState)}>
                {statCodes.map((item, idx) => (
                  <ConfirmDialog
                    key={idx}
                    btn={<MenuItem key={idx}>{item.name}</MenuItem>}
                    content={`상태를 ${item.name} 으로 변경합니다.`}
                    preHandle={async () => {
                      return true
                    }}
                    perform={() => {
                      onUpdate(item.code)
                      popupState.close()
                    }}
                  />
                ))}
              </Menu>
            </>
          )}
        </PopupState>
      ) : null}
    </>
  )
}

export default StateChanger
