import moment from 'moment'
import {sha256} from '@util/helper'
import CryptoJS from 'crypto-js'
const dateFormat = 'YYYY-MM-DD HH:mm:ss'

export const createOrder = (account) => {
  console.log('createOrder account=>', account)
  const now = moment().format(dateFormat)
  const random = Math.floor(Math.random() * 1000000)
  const ftime = moment().format('YYYYMMDDHHmmss');
  const name = account?.user?.name?? ''
  const phone = account?.user?.phone?? ''
  const phoneDial = account?.user?.phoneDial?? ''
  const phone1 = account?.user?.phone1?? ''
  const phone2 = account?.user?.phone2?? ''
  const password = account?.user?.password?? ''
  const password2 = account?.user?.password2?? ''
  const orderNo = `${ftime}${random}`.slice(0,16)
  const customId = CryptoJS.SHA256(`${name}${phone}`).toString().slice(0, 16)
  const order = {
    id: -1,
    orderNo: orderNo,
    userId: -1,
    name: name,
    phone: phone,
    phoneDial: phoneDial,
    phone1: phone1,
    phone2: phone2,
    customId: customId,
    paymentKey: '',
    password: password,
    password2: password2,
    fax:'',
    faxDial: '',
    fax1: '',
    fax2: '',
    email: '',
    recvCodes: '',
    recvs: [],
    statCode: 'S000',
    claimYn: 'N',
    amount: 0,
    recvAddr1: '',
    recvAddr2: '',
    remark: '',
    docCodes: '',
    depositor: name,
    note1: '',
    note2: '',
    note3: '',
    uptTime: now,
    regTime: now,
  }
  return order
}

export const createDoc = (docCode, account) => {
  const name = account?.user?.name?? ''
  const phone = account?.user?.phone?? ''
  const phoneDial = account?.user?.phoneDial?? '010'
  const phone1 = account?.user?.phone1?? ''
  const phone2 = account?.user?.phone2?? ''
  const now = moment().format(dateFormat)
  const doc = {
    id: -1,
    orderId: -1,
    basketId: -1,
    docCode: docCode ?? '',
    sepCode: '',
    statCode: 'S000',
    name: name,
    engName: '',
    phone:phone,
    phoneDial: phoneDial,
    phone1: phone1,
    phone2: phone2,
    civilNum: '',
    companyName: '',
    bizNum: '',
    addr1: '',
    addr2: '',
    hanYn: 'Y',
    prfFrom: null,
    prfTo: null,
    mockCode:'',
    mockContent:'',
    payer:'',
    migNum: '',
    migDate: null,
    trustAddr1: '',
    trustAddr2:'',
    aimCode: '',
    bizCode: '',
    factCode: '',
    civilnumYn: 'N',
    addrYn: 'N',
    bizDocReason: '',
    estCode: '',
    effYn: 'Y',
    corpCode: '',
    corpNum: '',
    branchYn: 'N',
    managerYn: 'N',
    historyYn: 'N',
    dajiYn: 'N', // TODO
    bldgOneYn: 'N', // TODO
    bldgChongYn: 'Y', // TODO
    houseOneYn: 'N',
    carCode: '',
    carNum: '',
    lastOwner: '',
    userMsg: '',
    giveYn: 'Y',
    price: 0,
    qty: 1,
    amount: 0,
    remark: '',
    uptTime: now,
    regTime: now,
  }
  return doc
}
