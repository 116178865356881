import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import MyDialog2 from '@components/MyDialog2'
import StateChanger from '@components/StateChanger'
import MyPagination from '@components/MyPagination'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ko'
import MobileLabel from '@components/MobileLabel'
import { truncate } from '@util/helper'
import AddCircleOutlineOutlined from '@mui/icons-material/AddCircleOutlineOutlined'
import NoticePartMod from './notice.part.mod'
import NoticePartView from './notice.part.view'
import { isAdmin, isRepManager } from '@util/helper'
import ConfirmDialog from '@components/ConfirmDialog'
const boldFont = {
  fontWeight: 'bold',
}

const defPageInfo = { pageNum: 0, pageSize: 10 }
const dateFormat = 'YYYY-MM-DD HH:mm:ss'

const NoticePage = ({ origin }) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useSelector((state) => state.accountReducer)
  const codes = useSelector((state) => state.codeReducer)
  const [records, set_records] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const curLocationRef = useRef(location)
  const pack = location.state?.pack
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const { alert, renderAlert } = useAlert()
  const [totalCount, set_totalCount] = useState(0)
  const [pageInfo, set_pageInfo] = useState(pack?.data ?? defPageInfo)
  const [termsKey, set_termsKey] = useState(0)
  const [terms, _set_terms] = useState({})
  const termsRef = useRef(terms)
  const recordRef = useRef({})
  const modHandler = useState(false)
  const viewHandler = useState(false)
  const fetchNotices = async () => {
    const url = '/notice'
    const resp = await WebService.get(url, {
      pageNum: pageInfo.pageNum,
      pageSize: pageInfo.pageSize,
    })

    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    const repMessage = JSON.parse(resp.repMessage)
    const totalCount = +repMessage.totalCount
    set_totalCount(totalCount)
    const records = JSON.parse(repMessage.records)
    return records
  }

  const onLoad = async () => {
    const records = await fetchNotices()
    set_records(records)
  }

  const onCreate = () => {
    const now = moment().format(dateFormat)
    const newRecord = {
      id: -1,
      parentId: 0,
      catCode: 'C001',
      userId: account.user.id,
      userName: '관리자',
      password: '',
      title: '',
      content: '',
      readCnt: 0,
      statCode: 'S001',
      fixYn: 'N',
      uptTime: now,
      regTime: now,
    }
    recordRef.current = newRecord
    // set_detailScreen(true)
    modHandler[1](true)
  }

  const onDelete = async (id) => {
    const url = `/notice`
    const resp = await WebService.delete(url, {
      id: id,
    })
    if (resp.repCode != 'ack') {
      alert(resp.repMessage)
      return
    }
    showSnackbar('삭제되었습니다.')
    set_pageInfo({ ...pageInfo })
  }
  useEffect(() => {
    onLoad()
  }, [pageInfo])

  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  return (
    <div
    // style={{
    //   paddingTop: isMobile ? 50 : 100,
    //   paddingBottom: 50,
    //   minHeight: '75vh',
    // }}
    >
      <Container>
        {modHandler[0] ? (
          <NoticePartMod record={recordRef.current} dialogHandler={modHandler} callback={onLoad} />
        ) : null}
        {viewHandler[0] ? (
          <NoticePartView record={recordRef.current} dialogHandler={viewHandler} callback={onLoad} />
        ) : null}
        {renderAlert()}
        <Grid container spacing={1} style={{ alignItems: 'center' }}>
          {isAdmin(account) ? (
            <Grid item xs={12} style={{ display: 'flex', alignItems: 'center' }}>
              <IconButton onClick={onCreate}>
                <AddCircleOutlineOutlined />
              </IconButton>
              <Typography>추가</Typography>
            </Grid>
          ) : null}
          {isMobile ? null : (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Typography style={boldFont}>제목</Typography>
                </Grid>
                <Grid item xs>
                  <Typography style={boldFont}>작성일시</Typography>
                </Grid>
                {isAdmin(account) ? (
                  <Grid item xs>
                    <Typography style={boldFont}>관리</Typography>
                  </Grid>
                ) : null}
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider />
          </Grid>
          {records.map((notice, idx) => (
            <Grid
              item
              xs={12}
              key={idx}
              style={{ display: 'flex', alignItems: 'center' }}
              onClick={() => {
                recordRef.current = notice
              }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} md>
                  <Typography
                    style={{ fontWeight: 'bold', cursor: 'pointer' }}
                    onClick={() => {
                      // viewHandler[1](true)
                      navigate(`/${origin}/notice/${notice.id}`, {
                        state: {
                          pack: {
                            data: notice,
                            url: window.location.pathname,
                          },
                        },
                      })
                    }}
                  >
                    {notice.title}
                  </Typography>
                </Grid>
                <Grid item xs={12} md>
                  <MobileLabel label='수정일시' />
                  <Typography style={{}}>{notice.uptTime}</Typography>
                </Grid>
                {isAdmin(account) ? (
                  <Grid item xs={12} md>
                    <MobileLabel label='관리' />
                    <FlatButton
                      size='small'
                      onClick={() => {
                        recordRef.current = notice
                        modHandler[1](true)
                      }}
                    >
                      수정
                    </FlatButton>{' '}
                    &nbsp;
                    <ConfirmDialog
                      btn={
                        <FlatButton  size='small' variant='outlined' color={'error'}>
                          삭제
                        </FlatButton>
                      }
                      title={'삭제'}
                      content={' 데이터를 삭제하시겠습니까?'}
                      bc={'error'}
                      preHandle={async () => {
                        return true
                      }}
                      perform={() => {
                        onDelete(notice.id)
                      }}
                    />
                  </Grid>
                ) : null}

                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <MyPagination totalCount={totalCount} pageInfo={pageInfo} set_pageInfo={set_pageInfo} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default NoticePage
