import React, { useState } from 'react'
import { Divider, Drawer, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from '@mui/material'
import { makeStyles } from '@mui/styles'
import { Link } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import Settings from '@mui/icons-material/Settings'
import Toolbar from '@mui/material/Toolbar'
import { useTheme } from '@mui/material/styles'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import { setActiveSideIndex } from '@redux/actions/activeSideIndexAction'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
const useStyles = makeStyles(() => ({
  link: {
    textDecoration: 'none',
    // color: "#fff",
    fontSize: '20px',
  },
  icon: {
    // color: "#fff"
  },
  right: {
    backgroundColor: '#fff',
  },
}))

const LeftDrawer = ({ sideMenu }) => {
  const classes = useStyles()
  const theme = useTheme()
  const [openDrawer, setOpenDrawer] = useState(false)
  const [activeIdx, set_activeIdx] = useState(0)
  const defIcon = <LabelOutlinedIcon size='small' sx={{ color: '#000' }} />
  const dispatch = useDispatch()
  const activeSideIndex = useSelector((state) => state.activeSideIndexReducer)
  console.log('sideMenu====>', sideMenu)
  const navigate = useNavigate()
  const sideMenu2 = [...sideMenu]

  return (
    <>
      <Drawer
        open={openDrawer}
        anchor={'left'}
        onClose={() => setOpenDrawer(false)}
        PaperProps={{
          className: classes.right,
          sx: { backgroundColor: '#fff' },
        }}
      >
        <Toolbar />

        <List>
          {
            sideMenu2?.map( (item, idx) =>(
                <div key={idx}>
                  <List style={{ backgroundColor: '#f5f5f5' }}>
                    <ListItem>{item.title}</ListItem>
                  </List>
                  {item?.list?.map((menu, idx2) =>
                    menu.visible ? (
                      menu.text === 'divider' ? (
                        <Divider />
                      ) : (
                        <ListItem
                          key={menu.id}
                          disablePadding
                          onClick={() => {
                            console.log('item.title=>>>>', item.title)
                            if(item.title === '홈메인') {
                              dispatch(setActiveSideIndex(0))
                            }
                            else {
                              dispatch(setActiveSideIndex(menu.id))
                            }
                            navigate(menu.to)
                            setOpenDrawer(false)
                          }}
                          style={{ justifyContent: 'center', alignItems: 'center' }}
                        >
                          <ListItemButton selected={menu.id === activeSideIndex}>
                            <ListItemIcon sx={{ minWidth: '35px' }}>{menu.icon ? menu.icon : defIcon}</ListItemIcon>
                            <ListItemText primaryTypographyProps={{ color: '#000' }} primary={menu.text} />
                          </ListItemButton>
                        </ListItem>
                      )
                    ) : null
                  )}
                </div>
              )
            )
          }
        </List>
      </Drawer>
      <IconButton onClick={() => setOpenDrawer(!openDrawer)} className={classes.icon}>
        <MenuIcon sx={{ color: '#000' }} />
      </IconButton>
    </>
  )
}

export default LeftDrawer
