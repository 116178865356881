import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import MyDialog2 from '@components/MyDialog2'
import MyPagination from '@components/MyPagination'
import MobileLabel from '@components/MobileLabel'
const boldFont = {
  fontWeight: 'bold',
}

const defPageInfo = { pageNum: 0, pageSize: 10 }
const canCancelCodes = ['S000', 'SA00', 'S001']
const MyIndexPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useSelector((state) => state.accountReducer)
  const codes = useSelector((state) => state.codeReducer)
  const [records, set_records] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const pack = location.state?.pack
  const curLocationRef = useRef(location)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const { alert, renderAlert } = useAlert()
  const [totalCount, set_totalCount] = useState(0)
  const [pageInfo, set_pageInfo] = useState(pack?.data ?? defPageInfo)
  const dgRef = useRef()
  const [html, set_html] = useState('')
  const fetchOrders = async () => {
    const url = `/order`
    const resp = await WebService.get(url, {
      pageNum: pageInfo.pageNum,
      pageSize: pageInfo.pageSize,
    })

    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    const repMessage = JSON.parse(resp.repMessage)
    const totalCount = +repMessage.totalCount
    set_totalCount(totalCount)
    const records = JSON.parse(repMessage.records)
    return records
  }

  const Minwon = () => {
    return (
      <div>
        <div style={{ height: '100vh' }} dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    )
  }

  const onLoad = async () => {
    const records = await fetchOrders()
    if (!records) {
      return
    }
    console.log('records=>', records)
    set_records(records)
  }

  useEffect(() => {
    onLoad()
  }, [pageInfo])

  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  useEffect(() => {
    if (!account?.user?.id) {
      navigate('/login')
    } else {
      onLoad()
    }
  }, [])

  return (
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
          paddingBottom: 50,
          minHeight: '75vh',
        }}
      >
        {renderAlert()}
        <MyDialog2 title={'Test'} ref={dgRef} component={<Minwon />} windowWidth='md' />
        <Container>
          <Grid container spacing={1} style={{ alignItems: 'center' }}>
            <Grid
              item
              xs={12}
              style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16 }}
            >
              <Typography style={boldFont}>민원처리</Typography>
            </Grid>
            {isMobile ? null : (
              <Grid item xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  <Grid item xs md>
                    <Typography style={boldFont}>주문번호</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>민원종류</Typography>
                  </Grid>
                  <Grid item xs={1} md={1}>
                    <Typography style={boldFont}>수량</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>금액</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>신청일시</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>최종수정일시</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>상태</Typography>
                  </Grid>
                  <Grid item xs md>
                    <Typography style={boldFont}>취소</Typography>
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid item xs={12}>
              <Divider />
            </Grid>
            {records.length > 0
              ? records.map((item, idx) => (
                  <>
                    <Grid item xs={12}>
                      <Grid
                        container
                        spacing={1}
                        key={idx}
                        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                      >
                        <Grid item xs={12} md>
                          <MobileLabel label='주문번호' />
                          <Typography variant='body2'>{item.orderNo}</Typography>
                        </Grid>
                        <Grid item xs md>
                          <Grid
                            container
                            spacing={1}
                            style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
                          >
                            <Grid item xs={12}>
                              {item.docs.map((doc, idx2) => (
                                <Grid container spacing={1} style={{ display: 'flex', alignItems: 'center' }}>
                                  <Grid item xs={12}>
                                    <Typography
                                      variant='body2'
                                      style={{ cursor: 'pointer', fontWeight: 'bold' }}
                                      onClick={() => {
                                        navigate('/my/order/view', {
                                          state: {
                                            order: item,
                                            pack: {
                                              url: window.location.pathname,
                                              data: pageInfo,
                                            },
                                          },
                                        })
                                      }}
                                    >
                                      {docCodes.find((r) => r.code === doc.docCode).name}
                                    </Typography>
                                  </Grid>
                                </Grid>
                              ))}
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={1} md={1}>
                          <Typography variant='body2'>{item.docs.length}</Typography>
                        </Grid>
                        <Grid item xs md>
                          <Typography variant='body2'>{comma(item.amount)}</Typography>
                        </Grid>
                        <Grid item xs={12} md>
                          <MobileLabel label='신청일시' />
                          <Typography variant='body2'>
                            {moment(item.regTime).format('YYYY-MM-DD')} <br />
                            {moment(item.regTime).format('HH:mm:ss')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md>
                          <MobileLabel label='최종수정일시' />
                          <Typography variant='body2'>
                            {moment(item.uptTime).format('YYYY-MM-DD')} <br />
                            {moment(item.uptTime).format('HH:mm:ss')}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md>
                          <Typography variant='body2'>
                            {statCodes.find((r) => r.code === item.statCode).name}
                          </Typography>
                        </Grid>
                        <Grid item xs={12} md>
                          {item.claimYn !== 'Y' ? (
                            <FlatButton
                              // disabled={!canCancelCodes.includes(item.statCode)}
                              style={{ fontSize: '0.9em' }}
                              onClick={() => {
                                if (!canCancelCodes.includes(item.statCode)) {
                                  alert('상태가 처리중 이후에 취소하기 위해서는 이메일 또는 전화로 문의해 주시기 바랍니다.')
                                  return
                                } else {
                                  navigate('/my/order/view', {
                                    state: {
                                      order: item,
                                      pack: {
                                        url: window.location.pathname,
                                        data: pageInfo,
                                      },
                                      claim: true,
                                    },
                                  })
                                }
                              }}
                            >
                              취소요청
                            </FlatButton>
                          ) : (
                            <Typography variant='body2'>취소요청됨</Typography>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                  </>
                ))
              : null}
            <Grid item xs={12}>
              <MyPagination totalCount={totalCount} pageInfo={pageInfo} set_pageInfo={set_pageInfo} />
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}

export default MyIndexPage
