import React, {useState, useEffect, useMemo, Fragment, useRef} from 'react';
import {Grid, Typography, Button, Container, useMediaQuery, useTheme } from '@mui/material'
import {GlobalStyle as gs}  from '@src/GlobalStyle'
import CloudQueueOutlinedIcon from '@mui/icons-material/CloudQueueOutlined';
import HubOutlinedIcon from '@mui/icons-material/HubOutlined';
import {companyPhone} from '@util/string'
import LocalPhoneOutlinedIcon from '@mui/icons-material/LocalPhoneOutlined';
const CustomerWorktimePage= () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return(
    <>
      <div
        style={{
          paddingTop: isMobile ? 50 : 100,
          paddingBottom: 50,
          minHeight:'75vh'
        }}
      >
        <Container>
          <Grid container spacing={2} style={{ alignItems: 'center' }}>
            <Grid item xs={12} style={{backgroundColor:gs.colors.cardBg, color:'#000', alignItems:'center', paddingBottom:16}} >
              <Typography >
                당사에서 대행하는 민원서류는 시/군/구청에서 발급하는 민원서류와 등기소에서 발급하는 등기부등본이 있으며, <br/>
                인터넷열람, 다운로드, 이메일, 팩스, 우편서비스를 통해 신속하고 안전하게 이용하실 수 있습니다.
              </Typography>
            </Grid>
          </Grid>
          <Grid container style={{marginTop:100}} spaing={2} component={'main'}>
            <Grid item xs={12} md={4}>
              <div style={{display:'flex', alignItems:'center'}}>
                <CloudQueueOutlinedIcon style={{fontSize:40, marginRight:5}}/> 
                <Typography variant='h4'> 인터넷 접수</Typography>
              </div>
              <Typography style={{marginTop:30, marginBottom:30}}>
                인터넷접수는 365일 24시간 가능합니다. 
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{display:'flex', alignItems:'center'}}>
                <HubOutlinedIcon style={{fontSize:35, marginRight:5}}/> 
                <Typography variant='h4'>업무처리 시간</Typography>
              </div>
              <Typography style={{marginTop:30, marginBottom:30}}>
                09:00 ~ 17:30 (월~금) <br/>
              </Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <div style={{display:'flex', alignItems:'center'}}>
                <LocalPhoneOutlinedIcon style={{fontSize:40, marginRight:5}}/> 
                <Typography variant='h4'>전화상담</Typography>
              </div>
              <Typography style={{marginTop:30, marginBottom:30}}>
                {companyPhone}
              </Typography>
            </Grid>
          </Grid>
        </Container>
        
      </div>
    </>
  )
}

export default CustomerWorktimePage
