import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
  TablePagination,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import MyDialog2 from '@components/MyDialog2'
import { check } from 'prettier'
import { setLoading } from '@context/LoadingContext'
import MyPagination from '@components/MyPagination'
import ConfirmDialog from '@components/ConfirmDialog'
const boldFont = {
  fontWeight: 'bold',
}

const defPageInfo = { pageNum: 0, pageSize: 10 }
const MyLeavePage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useSelector((state) => state.accountReducer)
  const codes = useSelector((state) => state.codeReducer)
  const [records, set_records] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const pack = location.state?.pack
  const curLocationRef = useRef(location)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const { alert, renderAlert } = useAlert()
  const dgRef = useRef()
  const [allChecked, set_allChecked] = useState(false)
  const [html, set_html] = useState('')
  const [totalCount, set_totalCount] = useState(0)
  const [pageInfo, set_pageInfo] = useState(pack?.data ?? defPageInfo)

  const handleAllChecked = (e) => {
    const checked = e.target.checked
    console.log('checked=>', checked)
    records.forEach((item) => {
      item.checked = checked
    })
    set_records([...records])
    set_allChecked(checked)
  }

  const handleEachChecked = (item, e) => {
    const checked = e.target.checked
    item.checked = checked
    const filtered = records.filter((r) => r.checked === checked)

    if (checked) {
      console.log('filtered length:', filtered.length)
      if (filtered.length === records.length) {
        console.log('ok all checked')
        set_allChecked(true)
      }
    } else {
      if (filtered.length !== records.length) {
        set_allChecked(false)
      }
    }

    set_records([...records])
  }


  const onLeave = async () => {
    const url = `/user/leave/${account.user.id}`
    const resp = await WebService.post(url)
    console.log('resp=====>', resp)

    if(resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    alert('탈퇴처리되었습니다.')

    navigate('/logout')
  }

  const onLoad = async () => {
  }

  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  useEffect(() => {
    if (!account?.user?.id) {
      navigate('/login')
    } else {
      onLoad()
    }
  }, [pageInfo])


  return (
    <div style={{ paddingTop: isMobile ? 50 : 100 }}>
      {renderAlert()}
      <Container maxWidth='xs' style={{}}>
        <Grid container spacing={10}>
          <Grid item xs={12}>
            <Typography
              variant='h5'
              style={{ fontWeight: 'bold', textAlign: 'center', color: theme.palette.warning.main }}
            >
              회원탈퇴
            </Typography>
          </Grid>
          <Grid item xs={12}>
            회원탈퇴 후에는 기존 거래내역을 포함한 본 사이트의 서비스를 더이상 이용할 수 없게 됩니다.
          </Grid>
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center' }}>
            <ConfirmDialog
              btn={
                <Button variant='contained' color={'error'}>
                  회원탈퇴
                </Button>
              }
              title={'회원탈퇴'}
              content={' 회원탈퇴를 진행 하시겠습니까?'}
              bc={'error'}
              preHandle={async () => {
                return true
              }}
              perform={() => {
                onLeave()
              }}
            />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default MyLeavePage
