import moment from 'moment'
import axios from 'axios'
import WebService from './webService'
const isEmpty = function (value) {
  if (
    value === '' ||
    value === null ||
    value === undefined ||
    (value !== null && typeof value === 'object' && !Object.keys(value).length)
  ) {
    return true
  } else {
    return false
  }
}

export const uuidv4 = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c == 'x' ? r : (r & 0x3) | 0x8
    return v.toString(16)
  })
}

export const encodeParams = (nameValues) => {
  let url = ''
  Object.keys(nameValues).forEach((key, idx) => {
    if (idx == 0) {
      url += `${key}=${encodeURI(encodeURIComponent(nameValues[key]))}`
    } else {
      url += `&${key}=${encodeURI(encodeURIComponent(nameValues[key]))}`
    }
  })
  return url
}

export const generateRandomString = (num) => {
  const characters = '1234567890'
  let result = ''
  const charactersLength = characters.length
  for (let i = 0; i < num; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }

  return result
}

export const csvToJson = (header, csv) => {
  const rows = csv.split('\n')
  const jsonArray = []
  for (let i = 0; i < rows.length; i++) {
    let obj = {}
    let row = rows[i].split(',')
    for (let j = 0; j < header.length; j++) {
      obj[header[j]] = row[j]
    }
    jsonArray.push(obj)
  }
  return jsonArray
}

export const isValidDate = (date) => {
  const valid = moment(date, 'YYYY-MM-DD', true).isValid()
  return valid
}

export const isValidIpAddress = (ipAddress) => {
  const regex = RegExp(/^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/)
  return regex.test(ipAddress)
}

export const comma = (str) => {
  if (str) {
    str = String(str)
    str = str.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    return str
  } else {
    return str
  }
}

export const getTextLength = (str) => {
  var len = 0
  for (var i = 0; i < str.length; i++) {
    if (escape(str.charAt(i)).length == 6) {
      len++
    }
    len++
  }
  return len
}

export const cutString = (str, len) => {
  var s = 0
  for (var i = 0; i < str.length; i++) {
    s += str.charCodeAt(i) > 128 ? 2 : 1
    if (s > len) return str.substring(0, i)
  }
  return str
}

export const getAction = (url, nameValue) => {
  let action = url
  const params = encodeParams(nameValue)
  action += params.length > 0 ? `?${params}` : ''
  return action
}

export const searchSchool = async (word) => {
  const newTerms = {}
  word = word.replace(/초/g, '')
  word = word.replace(/등/g, '')
  word = word.replace(/학/g, '')
  word = word.replace(/교/g, '')

  if (word) {
    newTerms.name = word
    const url = '/public/school'
    const resp = await WebService.get(url, { terms: JSON.stringify(newTerms) })

    if (resp.repCode === 'ack') {
      const repMessage = JSON.parse(resp.repMessage)
      const records = JSON.parse(repMessage.records)
      records.forEach((r) => {
        r.label = `${r.name}(${r.newZipCode}, ${r.newAddr1})`
      })
      return records
    }
  }
  return []
}

export const searchCompany = async (word) => {
  const newTerms = {}
  if (word) {
    newTerms.name = word
    const url = '/company'
    const resp = await WebService.get(url, { terms: JSON.stringify(newTerms) })

    if (resp.repCode === 'ack') {
      const repMessage = JSON.parse(resp.repMessage)
      const records = JSON.parse(repMessage.records)
      records.forEach((r) => {
        r.label = `${r.name}`
      })
      return records
    }
  } else {
    return []
  }
}

export const isAdmin = (account) => {
  if (account.hasOwnProperty('roleList')) {
    const found = account.roleList.find((it) => it.roleCode === 'S009')
    if (found) {
      return true
    }
  }
  return false
}

export const isDirectManager = (account, schoolId) => {
  if (account && account.schoolRelationList) {
    const found = account.schoolRelationList.find(
      (it) => it.school.id === schoolId && it.rltnCode === 'S001' && it.statCode === 'S002'
    )
    if (found) {
      return true
    }
  }
  return false
}

export const isManager = (account, schoolId) => {
  if (account && account.schoolRelationList) {
    const found = account.schoolRelationList.find(
      (it) => it.school.id === schoolId && it.rltnCode === 'S004' && it.statCode === 'S002'
    )
    if (found) {
      return true
    }
  }
  return false
}

export const isRepManager = (account, schoolId) => {
  let ret = false

  if (account && account.companyRelationList && account.companySchoolRelationList) {
    // 학교의 위탁업체
    const companyList = account.companySchoolRelationList
      .filter((it) => +it.school.id === +schoolId)
      .map((it) => it.company)

    for (var i = 0; i < companyList.length; i++) {
      const c = companyList[i]
      const idx = account.companyRelationList.findIndex((r) => r.company.id === c.id && r.rltnCode === 'S002')
      if (idx >= 0) {
        ret = true
        break
      }
    }
  }
  return ret
}

export const isStringNullOrEmpty = (str) => {
  return str === null || str === undefined || str.trim() === ''
}

export const copyProperties = (source, target) => {
  if (source instanceof Object && target instanceof Object) {
    Object.keys(source).forEach((key) => {
      target[key] = source[key]
    })
  }
}
export const printVariable = (title, variable) => {
  let target = variable
  if (target instanceof Object) {
    target = JSON.stringify(variable)
  } else if (target instanceof Array) {
    target = JSON.stringify(variable)
  }
}

export const getCodeName = (codes, codeGroupId, code) => {
  const g = codes.filter((c) => c.codeGroupId === codeGroupId)
  const idx = g.findIndex((c) => c.code === code)
  if (idx >= 0) {
    return g[idx].name
  } else {
    return 'unknown'
  }
}

export const findCode = (codes, codeGroupId, code) => {
  const found = codes.filter((d) => d.codeGroupId === codeGroupId).find((d) => d.code === code)
  return found
}

/**
 * return value not include max
 * @param max
 * @returns {number}
 */
export const getRandomInt = (max) => {
  return Math.floor(Math.random() * max)
}

export const fullCopy = (data) => {
  return JSON.parse(JSON.stringify(data))
}

export const emailRegex = /([\w.]+)@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.)|(([\w-]+\.)+))([a-zA-Z]{2,4}|[0-9]{1,3})(\]?)$/
// export const passwordRegex = /^(?=.*[a-zA-Z])(?=.*[!@#$%^*+=-])(?=.*[0-9]).{8,25}$/
// export const passwordRegex = /^(?!.*(.)\1\1)[a-zA-Z0-9!@#$%^&*(),.?":{}|<>]{6,}$/
export const passwordRegex = /^(?!.*(.)\1\1)[a-zA-Z0-9!@#$%^&*(),.?":{}|<>-_]{6,}$/
export const nameRegex = /^[가-힣]{2,4}|[a-zA-Z]{2,10}\s[a-zA-Z]{2,10}$/

export const engRegex = /^[a-zA-Z0-9.,'& -]+$/

export const getNow = () => {
  const dateFormat = 'YYYY-MM-DD HH:mm:ss'
  return moment().format(dateFormat)
}

export const detectBrowser = () => {
  let nVer = navigator.appVersion
  let nAgt = navigator.userAgent
  let browserName = navigator.appName
  let fullVersion = '' + parseFloat(navigator.appVersion)
  let majorVersion = parseInt(navigator.appVersion, 10)
  let nameOffset, verOffset, ix
  // In Opera, the true version is after "OPR" or after "Version"
  if ((verOffset = nAgt.indexOf('OPR')) != -1) {
    browserName = 'Opera'
    fullVersion = nAgt.substring(verOffset + 4)
    if ((verOffset = nAgt.indexOf('Version')) != -1) fullVersion = nAgt.substring(verOffset + 8)
  }
  // In MS Edge, the true version is after "Edg" in userAgent
  else if ((verOffset = nAgt.indexOf('Edg')) != -1) {
    browserName = 'Edge'
    fullVersion = nAgt.substring(verOffset + 4)
  }
  // In MSIE, the true version is after "MSIE" in userAgent
  else if ((verOffset = nAgt.indexOf('MSIE')) != -1) {
    browserName = 'Microsoft Internet Explorer'
    fullVersion = nAgt.substring(verOffset + 5)
  }
  // In Chrome, the true version is after "Chrome"
  else if ((verOffset = nAgt.indexOf('Chrome')) != -1) {
    browserName = 'Chrome'
    fullVersion = nAgt.substring(verOffset + 7)
  }
  // In Safari, the true version is after "Safari" or after "Version"
  else if ((verOffset = nAgt.indexOf('Safari')) != -1) {
    browserName = 'Safari'
    fullVersion = nAgt.substring(verOffset + 7)
    if ((verOffset = nAgt.indexOf('Version')) != -1) fullVersion = nAgt.substring(verOffset + 8)
  }
  // In Firefox, the true version is after "Firefox"
  else if ((verOffset = nAgt.indexOf('Firefox')) != -1) {
    browserName = 'Firefox'
    fullVersion = nAgt.substring(verOffset + 8)
  }
  // In most other browsers, "name/version" is at the end of userAgent
  else if ((nameOffset = nAgt.lastIndexOf(' ') + 1) < (verOffset = nAgt.lastIndexOf('/'))) {
    browserName = nAgt.substring(nameOffset, verOffset)
    fullVersion = nAgt.substring(verOffset + 1)
    if (browserName.toLowerCase() == browserName.toUpperCase()) {
      browserName = navigator.appName
    }
  }
  // trim the fullVersion string at semicolon/space if present
  if ((ix = fullVersion.indexOf(';')) != -1) fullVersion = fullVersion.substring(0, ix)
  if ((ix = fullVersion.indexOf(' ')) != -1) fullVersion = fullVersion.substring(0, ix)

  majorVersion = parseInt('' + fullVersion, 10)
  if (isNaN(majorVersion)) {
    fullVersion = '' + parseFloat(navigator.appVersion)
    majorVersion = parseInt(navigator.appVersion, 10)
  }

  return {
    name: browserName,
    fullVersion: fullVersion,
    majorVersion: majorVersion,
  }
}

export const sanitizeFilename = (filename) => {
  const regexpr = /[\/|\\*:"<>{}?%,]/g
  let sanitized = filename.replace(regexpr, '_')
  sanitized = sanitized.replace(/\s+/g, '_')
  return sanitized
}

export const download = async (serverUrl) => {
  const response = await axios.get(serverUrl, {
    responseType: 'blob', // Set responseType to 'blob' for binary data
  })

  if (!response) {
    alert('파일이 존재하지 않습니다.')
  }

  //
  const contentDispositionHeader = response.headers['content-disposition']
  const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/
  const matches = filenameRegex.exec(contentDispositionHeader)
  let suggestedFilename = 'downloaded_file' // Default filename if not found

  if (matches != null && matches[1]) {
    suggestedFilename = decodeURIComponent(matches[1]).replace(/['"]/g, '') // Remove quotes if present
  }

  // Create a Blob object from the response data
  const blob = new Blob([response.data], { type: response.headers['content-type'] })

  // Create a temporary URL for the Blob
  const url = window.URL.createObjectURL(blob)

  // Create a link element to trigger the download
  const link = document.createElement('a')
  link.href = url
  link.download = suggestedFilename
  document.body.appendChild(link)

  // Trigger the download
  link.click()

  // Clean up the temporary URL and link
  window.URL.revokeObjectURL(url)
  document.body.removeChild(link)
}

export const truncate = (str, maxLength) => {
  if (str.length > maxLength) {
    return str.substring(0, maxLength - 3) + '...'
  }
  return str
}

export const setLocalData = (name, value) => {
  if (name && value) {
    localStorage.setItem(name, JSON.stringify(value))
  } else {
    console.log(`setLocalData name:${name} value:${value} not valid`)
  }
}

export const getLocalData = (name) => {
  const value = localStorage.getItem(name)
  if (!value) {
    console.log(`getLocalData name:${name} value not valid`)
    return null
  }
  return JSON.parse(value)
}

export const removeLocalData = (name) => {
  if (name) {
    localStorage.removeItem(name)
  }
}

export const genMenu = (codes, codeGroupId, prefix, url) => {
  const menu = codes
    .filter((r) => r.codeGroupId === codeGroupId)
    .filter((r) => (!prefix ? true : r.code.startsWith(prefix)))
    .map((r) => {
      r.menuId = r.code
      r.label = r.name
      r.to = `${url}/${r.code}`
      return r
    })
  return menu
}

export const intersect = (array1, array2) => {
  const set1 = new Set(array1)
  const set2 = new Set(array2)
  return [...set1].filter((value) => set2.has(value))
}

export const dataUrlToFile = (dataUrl, filename) => {
  const [, mimeType, base64Data] = dataUrl.match(/^data:(.*);base64,(.*)$/)

  // Convert base64 data to a Blob
  const byteCharacters = atob(base64Data)
  const byteNumbers = new Array(byteCharacters.length)
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i)
  }
  const byteArray = new Uint8Array(byteNumbers)
  const blob = new Blob([byteArray], { type: mimeType })

  // Create a File object from the Blob
  return new File([blob], filename, { type: mimeType })
}

export const getAddress = (data, korean = true, road = true) => {
  if(!data) {
    return ''
  }

  let fullAddress = ''
  if(korean) {
    if(road) {
      fullAddress += data.roadAddress?? ''
    }
    else {
      fullAddress += data.jibunAddress?? ''
    }
  }
  else {
    if(road) {
      fullAddress += data.roadAddressEnglish?? ''
    }
    else {
      fullAddress += data.jibunAddressEnglish?? ''
    }
  }

  let extraAddress = ''
  if(korean) {
    if(road) {
      if(data.bname) {
        extraAddress += data.bname
      }
      if(data.buildingName) {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
      }
    }
    else {
    }
  }

  fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
  console.log('fullAddress=>', fullAddress)
  return fullAddress?? ''

  /*
  let fullAddress = data.address;
  let extraAddress = ''
  if (data.addressType === 'R') {
    if (data.bname !== '') {
      extraAddress += data.bname
    }
    if (data.buildingName !== '') {
      extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName
    }
    fullAddress += extraAddress !== '' ? ` (${extraAddress})` : ''
  }
  */
}


export const getImageTypeFromDataURL = (dataURL) => {
    const matches = dataURL.match(/^data:(image\/\w+);base64,/);
    if (matches) {
        const imageType = matches[1];
        // Append file extension based on image type
        return imageType === 'image/jpeg' ? 'jpg' :
               imageType === 'image/png' ? 'png' :
               null;
    }
    return null;
}

