import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
  TablePagination,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import { needPrfTerm } from '@pages/ask/semu/semu.util'

import useAlert from '@hooks/useAlert'
import MinwonView from '@components/MinwonView'
import StateChanger from '@components/StateChanger'
const RestPartView = ({ doc }) => {
  console.log('doc=>', doc)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const docCodes = codes.filter((r) => r.codeGroupId == 'D001')
  const corpCodes = codes.filter((r) => r.codeGroupId == 'D007')
  const carCodes = codes.filter((r) => r.codeGroupId == 'D008')
  const estCodes = codes.filter((r) => r.codeGroupId == 'D006')
  const bizCodes = codes.filter((r) => r.codeGroupId == 'D004')

  console.log('estCodes=>', estCodes)
  const account = useSelector((state) => state.accountReducer)
  const navigate = useNavigate()
  const location = useLocation()
  const curLocationRef = useRef(location)
  const { alert, renderAlert } = useAlert()

  const onLoad = async () => {}

  const quotePrice = (doc) => {
    const code = docCodes.find(r => r.code === doc.docCode)
    console.log('quotePrice code', code)
    if(code.code === 'DS07') {
      const code2 = bizCodes.find(r => r.code === doc.bizCode)
      return doc.giveYn === 'Y' ? code2.note2 : code2.note1
    }
    else if(code.code === 'DG01') {
      const code2 = estCodes.find(r => r.code === doc.estCode)
      return doc.giveYn === 'Y' ? code2.note2 : code2.note1
    }
    else {
      return doc.giveYn === 'Y' ? code.note2 : code.note1
    }
  }

  useEffect(() => {
    if (curLocationRef.current.key !== location.key) {
      curLocationRef.current = location
      onLoad()
    }
  }, [location])

  useEffect(() => {
    onLoad()
  }, [])

  return (
    <div style={{marginTop:0}}>
      {renderAlert()}
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={6}>
              <Typography variant='h5' style={{ fontWeight: 'bold' }}>
                {docCodes.find((r) => r.code === doc.docCode)?.name}{' '}
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <StateChanger record={doc} tbl={'docs'}/>
            </Grid>
          </Grid>
        </Grid>

        {/* 일반민원 */}
        {doc.docCode.startsWith('DG') ? (
          <>
            <MinwonView label='성명' component={<Typography>{doc.name}</Typography>} />
            <MinwonView
              label='전화번호'
              component={
                <Typography style={[]}>
                  {doc.phoneDial} - {doc.phone1} - {doc.phone2}
                </Typography>
              }
            />
            {doc.docCode === 'DG01' || doc.docCode === 'DG11' ? (
              <MinwonView
                label='부동산구분'
                component={<Typography>{estCodes.find((r) => r.code === doc.estCode).name}</Typography>}
              />
            ) : null}

            {doc.docCode === 'DG02' ? (
              <>
                <MinwonView
                  label='법인구분'
                  component={<Typography>{corpCodes.find((r) => r.code === doc.corpCode).name}</Typography>}
                />
                <MinwonView label='법인상호' component={<Typography>{doc.companyName}</Typography>} />
                {doc.branchYn === 'Y' || doc.managerYn === 'Y' ? (
                  <MinwonView
                    label='추가선택'
                    component={
                      <>
                        {doc.branchYn === 'Y' ? <Typography>지점 포함 </Typography> : null}
                        {doc.managerYn === 'Y' ? <Typography>지배인/대리인 포함 </Typography> : null}
                      </>
                    }
                  />
                ) : null}
              </>
            ) : null}

            {doc.docCode === 'DG03' ? (
              <>
                <MinwonView
                  label='토지구분'
                  component={
                    <Typography>{doc.dajiYn === 'N' ? '토지(임야)대장' : '토지(임야)대장 대지권등록부'}</Typography>
                  }
                />
                <MinwonView
                  label='연혁유무'
                  component={<Typography>{doc.historyYn === 'Y' ? '유' : '무'}</Typography>}
                />
              </>
            ) : null}

            {doc.docCode === 'DG04' ? (
              <>
                <MinwonView
                  label='건물구분'
                  component={
                    <Typography>{doc.bldgOneYn === 'Y' ? '일반(단독주택)' : '집합건물(아파트,연립주택등)'}</Typography>
                  }
                />
                <MinwonView
                  label='건물구분'
                  component={<Typography>{doc.bldgChongYn === 'Y' ? '총괄' : '일반'}</Typography>}
                />
              </>
            ) : null}
            {doc.docCode === 'DG08' ? (
              <MinwonView
                label='건물구분'
                component={
                  <Typography>{doc.houseOneYn === 'Y' ? '개발주택가격확인서' : '공동주택가격확인서'}</Typography>
                }
              />
            ) : null}
            {doc.docCode !== 'DG09' ? (
              <MinwonView
                label='주소지'
                component={
                  <Typography>
                    {doc.addr1} {doc.addr2}
                  </Typography>
                }
              />
            ) : null}

            {doc.docCode === 'DG01' || doc.docCode === 'DG02' ? (
              <MinwonView
                label='등기유형'
                component={<Typography>{doc.effYn === 'Y' ? '현재유효사항' : '말소사항포함'}</Typography>}
              />
            ) : null}

            {doc.docCode === 'DG09' ? (
              <>
                <MinwonView label='차량등록번호' component={<Typography>{doc.carNum}</Typography>} />
                <MinwonView label='최종소유주' component={<Typography>{doc.lastOwner}</Typography>} />
                <MinwonView label='권리구분' component={
                  <Typography>{carCodes.find(r => r.code === doc.carCode).name}</Typography>
                } />
              </>
            ) : null}
            <MinwonView
              label='열람용/제출용'
              component={<Typography>{doc.giveYn === 'Y' ? '제출용' : '열람용'}</Typography>}
            />
            <MinwonView label='수량' component={<Typography>{doc.qty} 매</Typography>} />

            <MinwonView label='금액' 
              component={
                <>
                <Typography>{comma(quotePrice(doc))} 원</Typography> 
                </>
              } 
            />
            <Grid item xs={12}>
              <Divider />
            </Grid>
          </>
        ) : null}
      </Grid>
    </div>
  )
}

export default RestPartView
