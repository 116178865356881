import axios from 'axios'
import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'

import { FormControl, InputLabel, Input, FormHelperText, Alert, Paper, Divider } from '@mui/material'
import WebService from '@util/webService'
import { encodeParams, getAction, isStringNullOrEmpty, printVariable, searchCompany } from '@util/helper'
// redux
import { useSelector, useDispatch } from 'react-redux'
// MUI Table
import CssBaseline from '@mui/material/CssBaseline'
import MUITable from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import TableFooter from '@mui/material/TableFooter'
// react-table
import { useTable, useExpanded } from 'react-table'
import ArrowRightIcon from '@mui/icons-material/ArrowRight'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import IconButton from '@mui/material/IconButton'
import CloseIcon from '@mui/icons-material/Close'
import SearchIcon from '@mui/icons-material/Search'
import AddCircleIcon from '@mui/icons-material/AddCircle'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Snackbar,
  TablePagination,
  TextField,
  withStyles,
  InputBase,
} from '@mui/material'
import { styled, alpha } from '@mui/material/styles'
import '@scss/App.scss'
import Button from '@mui/material/Button'
import FlatButton from '@components/FlatButton'
import useSnackbar from '@hooks/useSnackbar'
import MyTableHead from '@components/MyTableHead'
import useIndicator from '@hooks/useIndicator'
import Indicator from '@components/Indicator'
import MyDialog from '@components/MyDialog'
import Box from '@mui/material/Box'
import useDelegate from '@hooks/useDelegate'
// pagination
import { useTheme } from '@mui/material/styles'
import FirstPageIcon from '@mui/icons-material/FirstPage'
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import LastPageIcon from '@mui/icons-material/LastPage'
import { Avatar, Grid, Container } from '@mui/material/'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import moment from 'moment'
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete'
import { searchSchool } from '@util/helper'
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos'
import { useMediaQuery } from '@mui/material'
// import { navigate } from 'react-big-calendar/lib/utils/constants';
import { useNavigate } from 'react-router'
import { emailRegex, nameRegex, passwordRegex } from '@util/helper'
import ArrowBackOutlined from '@mui/icons-material/ArrowBackOutlined'
import useAlert from '@hooks/useAlert'
import ConfirmDialog from '@components/ConfirmDialog'
import { siteTitle } from '@util/string'
import { showSnackbar } from '@context/SnackbarContext'
const FormHelperTexts = styled(FormHelperText)`
  width: 100%;
  padding-left: 16px;
  font-weight: 700 !important;
  color: #d32f2f !important;
`

const PublicFindPasswordPage = () => {
  const [loginId, set_loginId] = useState('')
  const [phone, set_phone] = useState('')
  const [name, set_name] = useState('')
  const [sent, set_sent] = useState(false)
  const [openIndicator, closeIndicator, renderIndicator] = useIndicator()
  const { alert, renderAlert } = useAlert()
  const navigate = useNavigate()

  const [way, set_way] = useState('email')
  const canSubmit = () => {
    return loginId && name && loginId.length >= 2 && name.length >= 2 && !sent
  }

  const validate = () => {
    if(!emailRegex.test(loginId)) {
      showSnackbar('정상적인 이메일 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }

    if(!nameRegex.test(name)) {
      showSnackbar('정상적인 이름 를(을) 입력해 주세요.', theme.palette.warning.dark)
      return
    }
    return true
  }

  const onSubmit = async () => {
    const ret = validate()
    if(!ret) {
      return
    }
    const url = encodeURI(`/public/find/password/${way}`)
    const data = {
      name: name,
      loginId: loginId,
    }

    openIndicator()
    const resp = await WebService.post(url, data)
    closeIndicator()

    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }
    set_sent(true)
    alert('패스워드를 발송하였습니다.')
  }

  const handleInputPhone = (event) => {
    const value = event.target.value
    // Remove any non-numeric characters from the input
    const numericValue = value.replace(/[^0-9]/g, '')
    event.target.value = numericValue
  }

  return (
    <Container component='main' maxWidth='sm'>
      <div style={{ marginTop: 100 }}>
        <IconButton onClick={() => navigate(-1)}>
          <ArrowBackOutlined />
        </IconButton>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography  variant='h5' align='center' style={{fontWeight:'bold'}}>
            {siteTitle} 패스워드 재설정
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs={12}>
        <Paper fullWidth elevation={10} sx={{ padding: 5, mt: 5 }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                size='md'
                required
                fullWidth
                label={'이름'}
                name={'name'}
                inputProps={{
                  maxLength: 30,
                }}
                value={name}
                onChange={(e) => {
                  set_name(e.target.value.trim())
                }}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                size='md'
                required
                fullWidth
                label={'아이디(이메일)'}
                name={'loginId'}
                inputProps={{
                  maxLength: 30,
                }}
                value={loginId}
                onChange={(e) => {
                  set_loginId(e.target.value.trim())
                }}
              />
            </Grid>

            {/* <Grid item xs={12}> */}
            {/*   <TextField */}
            {/*     size='md' */}
            {/*     required */}
            {/*     fullWidth */}
            {/*     type='text' */}
            {/*     label={'휴대폰번호'} */}
            {/*     name={'phone'} */}
            {/*     inputProps={{ */}
            {/*       onInput: handleInputPhone, */}
            {/*       maxLength: 11, */}
            {/*     }} */}
            {/*     onChange={(e) => { */}
            {/*       set_phone(e.target.value.trim()) */}
            {/*     }} */}
            {/*   /> */}
            {/* </Grid> */}
            {/* <Grid item xs={12}> */}
            {/*   <Typography variant='h6' align='center'> */}
            {/*     {' '} */}
            {/*     패스워드 수신 방법 */}
            {/*   </Typography> */}
            {/*   <Divider /> */}
            {/* </Grid> */}
            {/* <Grid item xs={6} className='center' sx={{ alignItems: 'center' }}> */}
            {/*   <label> */}
            {/*     <input */}
            {/*       type='radio' */}
            {/*       name='way' */}
            {/*       checked={way === 'email'} */}
            {/*       value='email' */}
            {/*       onChange={(e) => { */}
            {/*         set_way('email') */}
            {/*       }} */}
            {/*     />{' '} */}
            {/*     이메일 */}
            {/*   </label> */}
            {/* </Grid> */}
            {/* <Grid item xs={6} className='center'> */}
            {/*   <label> */}
            {/*     <input */}
            {/*       type='radio' */}
            {/*       name='way' */}
            {/*       value='sms' */}
            {/*       checked={way === 'sms'} */}
            {/*       onChange={(e) => { */}
            {/*         set_way('sms') */}
            {/*       }} */}
            {/*     />{' '} */}
            {/*     SMS */}
            {/*   </label> */}
            {/* </Grid> */}
          </Grid>
        </Paper>

        <Grid container>
          <Grid item xs={12}>
            <ConfirmDialog
              btn={
                <Button
                  variant='contained'
                  size='lg'
                  fullWidth
                  disabled={!canSubmit()}
                  sx={{ mt: 2, fontSize: '1em' }}
                  color={'primary'}
                >
                  재설정
                </Button>
              }
              title={'알림'}
              content={`패스워드를 재설정 하시겠습니까?`}
              bc={'primary'}
              preHandle={async () => {
                return true
              }}
              perform={() => {
                onSubmit()
              }}
            />
          </Grid>
        </Grid>
      </Grid>
      <div>
        {renderIndicator()}
        {renderAlert()}
      </div>
    </Container>
  )
}

export default PublicFindPasswordPage
