import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import FlatButton from '@components/FlatButton'
import * as ExcelJS from 'exceljs'
import axios from 'axios'
import MyDialog2 from '@components/MyDialog2'
import StateChanger from '@components/StateChanger'
import MyPagination from '@components/MyPagination'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import 'dayjs/locale/ko'
import MobileLabel from '@components/MobileLabel'
import { truncate } from '@util/helper'
const boldFont = {
  fontWeight: 'bold',
}

const defPageInfo = { pageNum: 0, pageSize: 10 }

const userStateCodes=[
  {code:'S001', name:'가입회원'},
  {code:'N001', name:'비회원'},
  {code:'LEFT', name:'탈퇴'},
]
const AdminUserPage = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const account = useSelector((state) => state.accountReducer)
  const codes = useSelector((state) => state.codeReducer)
  const [records, set_records] = useState([])
  const navigate = useNavigate()
  const location = useLocation()
  const pack = location.state?.pack
  const curLocationRef = useRef(location)
  const docCodes = codes.filter((r) => r.codeGroupId === 'D001')
  const semuCodes = docCodes.filter((r) => r.code.startsWith('DS'))
  const restCodes = docCodes.filter((r) => r.code.startsWith('DG'))
  const recvCodes = codes.filter((r) => r.codeGroupId === 'C001')
  const statCodes = codes.filter((r) => r.codeGroupId === 'C002')
  const { alert, renderAlert } = useAlert()
  const [totalCount, set_totalCount] = useState(0)
  const [pageInfo, set_pageInfo] = useState(pack?.data ?? defPageInfo)
  const [termsKey, set_termsKey] = useState(0)
  const [terms, _set_terms] = useState({})
  const termsRef = useRef(terms)
  const fetchUsers = async () => {
    const url = '/user'
    const resp = await WebService.get(url, {
      pageNum: pageInfo.pageNum,
      pageSize: pageInfo.pageSize,
    })

    if (resp.repCode !== 'ack') {
      alert(resp.repMessage)
      return
    }

    const repMessage = JSON.parse(resp.repMessage)
    const totalCount = +repMessage.totalCount
    set_totalCount(totalCount)
    const records = JSON.parse(repMessage.records)
    return records
  }

  const onLoad = async () => {
    const records = await fetchUsers()
    set_records(records)
  }

  useEffect(() => {
    onLoad()
  }, [pageInfo])
  return (
    <div
      style={{
        paddingTop: isMobile ? 50 : 100,
        paddingBottom: 50,
        minHeight: '75vh',
      }}
    >
      <Container >
        {renderAlert()}
        <Grid container spacing={2} style={{ alignItems: 'center' }}>
          <Grid
            item
            xs={12}
            style={{ backgroundColor: gs.colors.cardBg, color: '#000', alignItems: 'center', paddingBottom: 16, }}
          >
            <Typography style={boldFont}>사용자</Typography>
          </Grid>
        
          {isMobile ? null : (
            <Grid item xs={12}>
              <Grid container spacing={1}>
                <Grid item xs>
                  <Typography style={boldFont}>사용자명</Typography>
                </Grid>
                <Grid item xs>
                  <Typography style={boldFont}>아이디</Typography>
                </Grid>
                <Grid item xs>
                  <Typography style={boldFont}>전화번호</Typography>
                </Grid>
                <Grid item xs>
                  <Typography style={boldFont}>등록일</Typography>
                </Grid>
                <Grid item xs>
                  <Typography style={boldFont}>상태</Typography>
                </Grid>
              </Grid>
            </Grid>
          )}
          <Grid item xs={12}>
            <Divider/>
          </Grid>
          {records.map((user, idx) => (
            <Grid item xs={12} key={idx}>
              <Grid container spacing={1}>
                <Grid item xs={12} md>
                  <Typography style={boldFont}>{user.name}</Typography>
                </Grid>
                <Grid item xs={12} md>
                  <Typography style={{}}>
                  {
                    user.statCode.startsWith('N') ? truncate(user.loginId, 24) : user.loginId
                  }
                  </Typography>
                </Grid>
                <Grid item xs={12} md>
                  <Typography style={{}}>{user.phone}</Typography>
                </Grid>
                <Grid item xs={12} md>
                  <MobileLabel label='등록일' />
                  <Typography style={{}}>{user.regTime}</Typography>
                </Grid>
                <Grid item xs={12} md>
                  <MobileLabel label='상태' />
                  <Typography style={{}}>
                  {
                    userStateCodes.find(r => r.code === user.statCode)?.name 
                  }
                  {
                    `(${user.statCode})` 
                  }
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Divider />
                </Grid>
              </Grid>
            </Grid>
          ))}
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'flex-end', alignItems: 'center' }}>
            <MyPagination totalCount={totalCount} pageInfo={pageInfo} set_pageInfo={set_pageInfo} />
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default AdminUserPage
