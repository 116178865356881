import React, { useState, useEffect, useMemo, Fragment, useRef } from 'react'
import IconButton from '@mui/material/IconButton'
import {
  Checkbox,
  FormGroup,
  FormControlLabel,
  Typography,
  Snackbar,
  TablePagination,
  TextField,
  withStyles,
  InputBase,
  Box,
} from '@mui/material'
import { useTheme } from '@mui/material/styles';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import LastPageIcon from '@mui/icons-material/LastPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';

const TablePaginationActions = (props) => {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton onClick={handleFirstPageButtonClick} disabled={page === 0} aria-label='first page'>
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton onClick={handleBackButtonClick} disabled={page === 0} aria-label='previous page'>
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

const MyPagination = ({totalCount, pageInfo, set_pageInfo}) => {

  const onPageChange = (event, newPage) => {
    set_pageInfo({...pageInfo, pageNum: newPage })
  }
  
  const onRowsPerPageChange = (event) => {
    const pageSize = parseInt(event.target.value, 10)
    set_pageInfo({pageNum:0, pageSize: pageSize})
  }

  return (
    <TablePagination
      component={'div'}
      rowsPerPageOptions={[10, 20, 50, 100]}
      count={totalCount}
      rowsPerPage={pageInfo.pageSize}
      page={pageInfo.pageNum}
      onPageChange={onPageChange}
      onRowsPerPageChange={onRowsPerPageChange}
      ActionsComponent={TablePaginationActions}
      labelDisplayedRows={({ from, to, count }) => {
        // return '' + from + '-' + to + ' 개 ' + count
        return `${from} - ${to} of ${count}`
      }}
      labelRowsPerPage={''}
    />
  )
}

export default MyPagination
