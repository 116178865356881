import * as React from 'react'
import { useEffect, useState } from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import AppBar from '@mui/material/AppBar'
import CssBaseline from '@mui/material/CssBaseline'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemButton from '@mui/material/ListItemButton'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import InboxIcon from '@mui/icons-material/MoveToInbox'
import MailIcon from '@mui/icons-material/Mail'
import { useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'
import DrawerComponent from './Drawer'
import { Outlet } from 'react-router-dom'
import { makeStyles } from '@mui/styles'
import LabelOutlinedIcon from '@mui/icons-material/LabelOutlined'
import { useTheme } from '@mui/material/styles'
// redux
import { useSelector, useDispatch } from 'react-redux'
// import logo from '@images/logo-tns.png'
import logo from '@images/logo_white.png'
import useStyles from '@hooks/useStyles'
import CommonDrawer from '@components/CommonDrawer'
import { siteTitle } from '@util/string'
import { genMenu } from '@util/helper'
const defSx = { color: '#212121', fontSize: 20 }
const drawerWidth = 240
const mainColor = '#33c058'


export default function AskRestDrawer() {
  const classes = useStyles()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  // redux
  const codeGroups = useSelector((state) => state.codeGroupReducer)
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)

  const [sideMenu, set_sideMenu]  = useState([])

  useEffect(() => {
    // const semuMenu = genMenu(codes, 'D001' , 'DS', `/ask/semu`)
    const restMenu = genMenu(codes, 'D001' , 'DG', `/ask/rest`)
    const sideMenu = [
      {
        title: '일반민원신청',
        list: restMenu.map((item) => {
          const d = {id:item.id, text: item.label, to: item.to, visible: true, icon: <LabelOutlinedIcon size='small' sx={defSx} /> }
          return d
        }),
      },
    ]
    set_sideMenu(sideMenu)

  }, [codes])
  return <CommonDrawer sideMenu={sideMenu} showSideEveryScreen={true} title={siteTitle} barBgColor={'#fff'} />
}
