
import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material'

import 'dayjs/locale/ko'

const MobileLabel = ({variant, label}) => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  return(
    <>
      {
        isMobile ? (
          <Typography variant={variant?? 'body2'} style={{marginRight:10}}>{label}</Typography>
        ) : null
      }
    </>
  )
}

export default MobileLabel
