import * as React from 'react'
import { useState, useEffect, useRef } from 'react'
import {
  Grid,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
  Container,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormControlLabel,
  Radio,
  Button,
  Checkbox,
  RadioGroup,
  IconButton,
} from '@mui/material'

import { showSnackbar } from '@context/SnackbarContext'
import { useParams, useLocation, useNavigate } from 'react-router-dom'
import moment from 'moment'
import { GlobalStyle as gs } from '@src/GlobalStyle'
import { useSelector, useDispatch } from 'react-redux'
import AddrPopup from '@components/AddrPopup'
import RecvPopup from '@components/RecvPopup'
import WebService from '@util/webService'
import { comma } from '@src/util/helper'
import useAlert from '@hooks/useAlert'
import { useSearchParams } from 'react-router-dom'
import axios from 'axios'
const OrderWait = () => {
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))
  const codes = useSelector((state) => state.codeReducer)
  const account = useSelector((state) => state.accountReducer)
  const navigate = useNavigate()
  const location = useLocation()
  const amount = location.state.amount
  console.log('wait_amount=>', amount)
  const curLocationRef = useRef(location)
  const { alert, renderAlert } = useAlert()

  return (
    <div style={{ paddingTop: isMobile ? 50 : 100 }}>
      {renderAlert()}
      <Container maxWidth='xs' style={{}}>
        <Grid container spacing={5}>
          <Grid item xs={12}>
            <Typography
              variant='h5'
              style={{ fontWeight: 'bold', textAlign: 'center', color: theme.palette.success.main }}
            >
              무통장 입금
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={1}>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography variant='h6' style={{ fontWeight: 'bold', marginRight: 10 }}>
                  예금주:
                </Typography>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                  손미희
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography variant='h6' style={{ fontWeight: 'bold', marginRight: 10 }}>
                  케이뱅크
                </Typography>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                  100-210-735873
                </Typography>
              </Grid>
              <Grid item xs={12} style={{ display: 'flex' }}>
                <Typography variant='h6' style={{ fontWeight: 'bold', marginRight: 10 }}>
                  금액: 
                </Typography>
                <Typography variant='h6' style={{ fontWeight: 'bold' }}>
                  {amount}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Typography variant='h7' bstyle={{ textAlign: 'center' }}>
              위의 계좌로 입금 시  주문이 진행됩니다.{'\n'}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default OrderWait
